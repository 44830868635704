import React from "react";
import { Field } from "formik";

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  textarea: {
    width: "100%",
  },
});

const Subtable = (props) => {
  const classes = useStyles();
  const { round, clues } = props;

  return (
    <Table size="small">
      <TableHead>
        <TableCell>
          <Typography>DD?</Typography>
        </TableCell>
        <TableCell>
          <Typography>Value</Typography>
        </TableCell>
        <TableCell>
          <Typography>Category</Typography>
        </TableCell>
        <TableCell>
          <Typography>Clue</Typography>
        </TableCell>
        <TableCell>
          <Typography>Response</Typography>
        </TableCell>
        <TableCell>
          <Typography>Media</Typography>
        </TableCell>
      </TableHead>
      <TableBody>
        {clues.map((clue) => (
          <TableRow>
            <TableCell>
              <Typography>
                <Field
                  component="input"
                  type="checkbox"
                  name={`game[${round}][clues][${clue.clueId}][daily_double]`}
                />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <Field
                  component="input"
                  className={classes.textarea}
                  name={`game[${round}][clues][${clue.clueId}][value]`}
                />
              </Typography>
            </TableCell>
            <TableCell>
              <Field
                as="textarea"
                className={classes.textarea}
                name={`game[${round}][clues][${clue.clueId}][category]`}
              />
            </TableCell>
            <TableCell>
              <Field
                as="textarea"
                className={classes.textarea}
                name={`game[${round}][clues][${clue.clueId}][body]`}
              />
            </TableCell>
            <TableCell>
              <Field
                className={classes.textarea}
                name={`game[${round}][clues][${clue.clueId}][response]`}
              />
            </TableCell>
            <TableCell>
              <Field
                className={classes.textarea}
                name={`game[${round}][clues][${clue.clueId}][media]`}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const MainClues = (props) => {
  const classes = useStyles();
  const { game, round } = props;

  const board = game[round];
  const clueSets =
    board &&
    board.categories.map((category, index) => {
      const startingClueIndex = index * 5;
      const clueSet = board.clues.slice(
        startingClueIndex,
        startingClueIndex + 5
      );
      return {
        category: category,
        clues: clueSet,
      };
    });

  if (!board) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {clueSets.map((clueSet) => (
            <TableRow>
              <TableCell>
                <Typography>Category {clueSet.category.index + 1}</Typography>
                <Field
                  as="textarea"
                  className={classes.textarea}
                  name={`game[${round}][categories][${clueSet.category.index}][name]`}
                />
              </TableCell>
              <Subtable clues={clueSet.clues} round={round} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const FinalJeopardyClue = () => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography>category</Typography>
              <Field
                as="textarea"
                className={classes.textarea}
                name={`game[final_jeopardy][category]`}
              />
            </TableCell>
            <TableCell>
              <Typography>category (change this too)</Typography>
              <Field
                as="textarea"
                className={classes.textarea}
                name={`game[final_jeopardy][clues][0][category]`}
              />
            </TableCell>
            <TableCell>
              <Typography>clue</Typography>

              <Field
                as="textarea"
                className={classes.textarea}
                name={`game[final_jeopardy][clues][0][body]`}
              />
            </TableCell>
            <TableCell>
              <Typography>response</Typography>

              <Field
                className={classes.textarea}
                name={`game[final_jeopardy][clues][0][response]`}
              />
            </TableCell>
            <TableCell>
              <Typography>media</Typography>

              <Field
                className={classes.textarea}
                name={`game[final_jeopardy][clues][0][media]`}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
