export function defaultGameState() {
  return {
    wager: 0,
    game: {
      currentRound: "",
      currentClue: null,
      finalInputEnabled: false,
      logoBackground: true,
    },
    players: {
      1: {
        index: 1,
        name: "Player 1",
        score: 0,
      },
      2: {
        index: 2,
        name: "Player 2",
        score: 0,
      },
      3: {
        index: 3,
        name: "Player 3",
        score: 0,
      },
    },
    buzzer: {
      armed: false,
      activePlayer: null,
      playerInControl: null,
      activatedAt: 0,
    },
    soundPlaying: {
      name: null,
    },
    finals: {
      1: {
        response: "",
        wager: 0,
      },
      2: {
        response: "",
        wager: 0,
      },
      3: {
        response: "",
        wager: 0,
      },
    },
    activeResponse: {},
    finalReveals: {},
    signatures: { players: {} },
    theme: { key: "season37" },
  };
}

export function rando(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function getRoomCode() {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var charactersLength = characters.length;
  for (var i = 0; i < 4; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
