import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { themes } from "../../../themes/getTheme";

const ThemeSettings = (props) => {
  const { theme, updateTheme } = props;

  return (
    <FormControl variant="outlined">
      <InputLabel>Theme</InputLabel>
      <Select
        label="Theme"
        value={theme.key}
        onChange={(event) => {
          updateTheme(event.target.value);
        }}
      >
        {Object.keys(themes).map((themeKey) => (
          <MenuItem key={themeKey} value={themeKey}>{themes[themeKey].name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ThemeSettings;
