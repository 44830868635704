import Season37Theme from "./Season37Theme";
import Season36Theme from "./Season36Theme";
import Season35Theme from "./Season35Theme";
import Season34Theme from "./Season34Theme";
import Season33Theme from "./Season33Theme";
import Season32Theme from "./Season32Theme";
import Season29Theme from "./Season29Theme";
import Season28Theme from "./Season28Theme";
import Season24Theme from "./Season24Theme";
import Season17Theme from "./Season17Theme";
import Season2Theme from "./Season2Theme";
import SuperJeopardyTheme from "./SuperJeopardyTheme";
import SpaceTheme from "./SpaceTheme";
import GoatTheme from "./GoatTheme";
import SportsTheme from "./SportsTheme";
import RockAndRollTheme from "./RockAndRollTheme";
import ChaosTheme from "./ChaosTheme";
import MillionaireTheme from "./MillionaireTheme";
import AfterDarkTheme from "./AfterDarkTheme";

export const themes = {
  season37: {
    name: "Season 37",
    theme: new Season37Theme(),
  },
  season36: {
    name: "Season 36",
    theme: new Season36Theme(),
  },
  season35: {
    name: "Season 35",
    theme: new Season35Theme(),
  },
  season34: {
    name: "Season 34",
    theme: new Season34Theme(),
  },
  season33: {
    name: "Season 33",
    theme: new Season33Theme(),
  },
  season32: {
    name: "Season 32",
    theme: new Season32Theme(),
  },
  season29: {
    name: "Season 29",
    theme: new Season29Theme(),
  },
  season28: {
    name: "Season 28",
    theme: new Season28Theme(),
  },
  season24: {
    name: "Season 24",
    theme: new Season24Theme(),
  },
  season17: {
    name: "Season 17",
    theme: new Season17Theme(),
  },
  season2: {
    name: "Season 2",
    theme: new Season2Theme(),
  },
  goat: {
    name: "Greatest of All Time",
    theme: new GoatTheme(),
  },
  sports: {
    name: "Sports Jeopardy!",
    theme: new SportsTheme(),
  },
  rockandroll: {
    name: "Rock & Roll Jeopardy!",
    theme: new RockAndRollTheme(),
  },
  super: {
    name: "Super Jeopardy!",
    theme: new SuperJeopardyTheme(),
  },
  space: {
    name: "Jeopardy... in SPACE!",
    theme: new SpaceTheme(),
  },
  chaos: {
    name: "Chaos Jeopardy!",
    theme: new ChaosTheme(),
  },
  afterdark: {
    name: "Jeopardy! After Dark",
    theme: new AfterDarkTheme(),
  },
  millionaire: {
    name: "Who Wants to Be in Jeopardy!?",
    theme: new MillionaireTheme(),
  },
};

export default function getTheme(themeName) {
  return themes[themeName]?.theme || new Season37Theme();
}
