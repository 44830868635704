import React from "react";
import Rebase from "re-base";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

var app = firebase.initializeApp({
  projectId: "j-replay",
  apiKey: "AIzaSyC7lmFejiKQDRzXapCf6CAjx_GzGjws9yw",
  authDomain: "j-replay.firebaseapp.com",
  databaseURL: "https://j-replay.firebaseio.com",
  storageBucket: "j-replay.appspot.com",
  messagingSenderId: "1065629429584",
});

export const base = Rebase.createClass(app.firestore());

export const firebaseAppAuth = app.auth();
export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export const FirebaseLogin = () => (
  <StyledFirebaseAuth
    firebaseAuth={firebase.auth()}
    uiConfig={{
      signInFlow: "redirect",
      signInSuccessUrl: "/host",
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    }}
  />
);

export const Unauthorized = ({ signOut }) => (
  <>
    <h3>Unauthorized</h3>
    <p>
      Your account is not authorized to start new games. Please email
      hello@devdev.ca to request access.
    </p>
    <button onClick={signOut}>Log out</button>
  </>
);

export default firebase;
