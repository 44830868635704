import React, { Component } from "react";
import { base } from "../../base";
import sortBy from "lodash/sortBy";
import shuffle from "lodash/shuffle";

import { defaultGameState } from "../../helpers/applicationHelper";
import getTheme from "../../themes/getTheme";

import HostScreen from "./HostScreen";

class GameLogic extends Component {
  state = defaultGameState();

  componentDidMount() {
    const { params } = this.props.match;
    params.gameId = params.gameId.toUpperCase();

    base.syncDoc(`games/${params.gameId}`, {
      context: this,
      state: "game",
    });

    base.syncDoc(`buzzers/${params.gameId}`, {
      context: this,
      state: "buzzer",
    });

    base.syncDoc(`players/${params.gameId}`, {
      context: this,
      state: "players",
    });

    base.syncDoc(`soundPlaying/${params.gameId}`, {
      context: this,
      state: "soundPlaying",
    });

    base.syncDoc(`finals/${params.gameId}/data/activeResponse`, {
      context: this,
      state: "activeResponse",
    });

    base.syncDoc(`theme/${params.gameId}`, {
      context: this,
      state: "theme",
    });

    base.listenToCollection(`finals/${params.gameId}/data`, {
      context: this,
      then(finals) {
        this.setFinalJeopardyData(finals);
      },
    });

    base.listenToCollection(`mobileBuzzes/${params.gameId}/buzzes`, {
      context: this,
      then(buzzes) {
        this.setState({ buzzes });

        if (
          buzzes.length > 0 &&
          !this.state.game.slowBuzzerMode &&
          !this.state.fastBuzzerTimeoutId
        ) {
          this.setCurrentPlayer(this.fastestPlayer());
        }
      },
    });
  }

  updateTheme = (themeKey) => {
    const theme = { ...this.state.theme };
    theme["key"] = themeKey;
    this.setState({ theme });
  };

  updateGame = (game) => {
    this.setState({ game });
  };

  updatePlayers = (players) => {
    this.setState({ players });
  };

  fastestPlayer = () => {
    return this.state.buzzes.sort((a, b) =>
      a.speed > b.speed ? 1 : b.speed > a.speed ? -1 : 0
    )[0]?.player;
  };

  async armBuzzers() {
    if (this.state.buzzer.armed) {
      return;
    }

    this.clearBuzzerTimeouts();
    this.removePastBuzzes();

    const buzzer = { ...this.state.buzzer };

    buzzer["armed"] = true;
    buzzer["activePlayer"] = null;

    this.setBuzzerTimeouts();

    this.setState({ buzzer });
  }

  setBuzzerTimeouts() {
    const slowBuzzerTimeoutId = setTimeout(() => {
      const fastestPlayer = this.fastestPlayer();

      if (!!fastestPlayer) {
        this.setCurrentPlayer(fastestPlayer);
      } else {
        this.expireBuzzers();
      }
      this.setState({ slowBuzzerTimeoutId: null });
    }, this.state.game.buzzerTimeout * 1000);

    const fastBuzzerTimeoutId = setTimeout(() => {
      const fastestPlayer = this.fastestPlayer();

      if (!this.state.game.slowBuzzerMode && !!fastestPlayer) {
        this.setCurrentPlayer(fastestPlayer);
        clearTimeout(slowBuzzerTimeoutId);
        this.setState({
          fastBuzzerTimeoutId: null,
          slowBuzzerTimeoutId: null,
        });
      }
      this.setState({ fastBuzzerTimeoutId: null });
    }, 1000);

    this.setState({ slowBuzzerTimeoutId, fastBuzzerTimeoutId });
  }

  clearBuzzerTimeouts() {
    clearTimeout(this.state.slowBuzzerTimeoutId);
    clearTimeout(this.state.fastBuzzerTimeoutId);
    this.setState({ slowBuzzerTimeoutId: null, fastBuzzerTimeoutId: null });
  }

  expireBuzzers() {
    if (!this.state.buzzer.activePlayer && this.state.buzzer.armed) {
      this.resetBuzzers();
      this.resetCurrentClue();
    }
  }

  async resetBuzzers(playerInControl = null) {
    this.resetActiveResponse();
    this.removePastBuzzes();
    if (this.state.buzzer.armed) {
      this.playTimeoutSound();
    }
    const buzzer = { ...this.state.buzzer };

    buzzer["armed"] = false;
    buzzer["activePlayer"] = null;

    if (!!playerInControl) {
      buzzer["playerInControl"] = this.state.players[playerInControl.index];
    }

    this.setState({ buzzer });
    this.unlockPlayers();
  }

  unlockPlayers() {
    const players = { ...this.state.players };
    [...Array(8).keys()].forEach((key) => {
      players[key].locked = false;
    });
    this.setState({ players });
  }

  removePastBuzzes() {
    const { params } = this.props.match;
    base.removeFromCollection(`mobileBuzzes/${params.gameId}/buzzes`, {
      context: this,
    });
  }

  updateVolume(volume) {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["volume"] = volume;
    this.setState({ soundPlaying });
  }

  playTimeoutSound() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = "timeOut";
    this.setState({ soundPlaying });
  }

  playDailyDoubleSound() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = "dailyDouble";
    this.setState({ soundPlaying });
  }

  displayClue(clue) {
    const game = { ...this.state.game };
    game[this.currentRound()].clues[clue.clueId].viewed = true;
    game["currentClue"] = clue;
    this.setState({ game });
  }

  markCorrectResponse() {
    if (!!this.state.buzzer.activePlayer) {
      this.adjustScore(this.state.buzzer.activePlayer, 1);
      this.resetBuzzers(this.state.buzzer.activePlayer);
      this.resetCurrentClue();
    }
  }

  markIncorrectResponse() {
    if (!!this.state.buzzer.activePlayer) {
      const player = this.state.buzzer.activePlayer;
      this.adjustScore(player, -1);

      const players = { ...this.state.players };
      players[player.index].locked = true;
      this.setState({ players }, () => {
        if (
          this.state.game.currentRound !== "final_jeopardy" &&
          this.state.game.currentClue &&
          !this.state.game.currentClue.daily_double &&
          Object.values(this.players()).some((player) => !player.locked)
        ) {
          this.armBuzzers();
        } else {
          this.resetBuzzers();
          this.resetCurrentClue();
        }
      });
    }
  }

  // Pass 1 to increase score, -1 to decrease score.
  adjustScore(player, direction) {
    const players = { ...this.state.players };
    players[player.index].score += this.currentValue() * direction;
    this.setState({ players });
  }

  adjustScoreArbitrary(player, score) {
    const players = { ...this.state.players };
    players[player.index].score = parseInt(score);
    this.setState({ players });
  }

  resetCurrentClue() {
    const game = { ...this.state.game };
    game["currentClue"] = null;
    this.setState({ game });
  }

  currentValue() {
    if (this.parseValue(this.state.wager) > 0) {
      return this.parseValue(this.state.wager);
    } else if (!!this.state.game.currentClue) {
      return this.parseValue(this.state.game.currentClue.value);
    } else {
      return 0;
    }
  }

  parseValue(value) {
    value = value || "";
    const numericValue = value.toString().replace(/\D/g, "");
    const parsedValue = parseInt(numericValue);

    if (isNaN(parsedValue)) {
      return 0;
    }

    return parsedValue;
  }

  updateWager(wager) {
    this.setState({ wager: wager });
  }

  currentRound() {
    if (!this.state.game) {
      return "";
    } else {
      return this.state.game.currentRound;
    }
  }

  setCurrentRound = (round) => {
    const game = { ...this.state.game };
    game["currentRound"] = round;
    game["currentClue"] = null;
    this.setState({ game });

    const rankings = sortBy(this.players(), ["score"]).reverse();

    if (round !== "jeopardy") {
      const [lastPlace] = rankings.slice(-1);
      this.giveControl(lastPlace);
    }

    if (round === "final_jeopardy") {
      let finalReveals = {};
      rankings.forEach(
        (player, index) =>
          (finalReveals[player.index] = {
            revealed: false,
            index: player.index,
            name: player.name,
            rank: index,
          })
      );
      this.setState({ finalReveals });
    }
  };

  setFinalRevealed = (player) => {
    const finalReveals = { ...this.state.finalReveals };
    finalReveals[player.index].revealed = true;
    this.setState({ finalReveals });
  };

  setCurrentPlayer = (player, force = false) => {
    const buzzer = { ...this.state.buzzer };
    if (buzzer.armed || this.currentRound() === "final_jeopardy" || force) {
      buzzer["armed"] = false;
      if (player) {
        buzzer["activePlayer"] = player;
      } else {
        buzzer["activePlayer"] = null;
      }
      this.setState({ buzzer });
    }
  };

  setMediaStatus = (status) => {
    const game = { ...this.state.game };
    game["mediaStatus"] = status;
    this.setState({ game });
  };

  setFinalJeopardyData = (finals) => {
    this.setState({ finals });
  };

  revealResponse = (player) => {
    this.setCurrentPlayer(player);
    const activeResponse = { ...this.state.activeResponse };
    activeResponse["response"] =
      this.state.finals[player.index]["response"] || "?";
    activeResponse["wager"] = null;

    this.setFinalRevealed(player);
    this.setState({ activeResponse });
  };

  revealWager = (player) => {
    const activeResponse = { ...this.state.activeResponse };
    activeResponse["wager"] = this.state.finals[player.index]["wager"] || 0;
    this.setState({ activeResponse });
    this.updateWager(activeResponse["wager"]);
  };

  resetActiveResponse() {
    this.setState({ activeResponse: { wager: null, response: null } });
  }

  updateClue = (key, body) => {
    const game = { ...this.state.game };
    game[this.currentRound()].clues[key].body = body;
    this.setState({ game });
  };

  updateCategory = (key, category) => {
    const game = { ...this.state.game };
    game[this.currentRound()].categories[key].name = category;
    this.setState({ game });
  };

  updateClueCategory = (key, category) => {
    const game = { ...this.state.game };
    game[this.currentRound()].clues[key].category = category;
    this.setState({ game });
  };

  updateMedia = (key, media) => {
    const game = { ...this.state.game };
    game[this.currentRound()].clues[key].media = media;
    this.setState({ game });
  };

  updateResponse = (key, response) => {
    const game = { ...this.state.game };
    game[this.currentRound()].clues[key].response = response;
    this.setState({ game });
  };

  updateBuzzerTimeout = (newTimeout) => {
    const game = { ...this.state.game };
    game["buzzerTimeout"] = newTimeout;
    this.setState({ game });
  };

  toggleBuzzerMode = () => {
    const game = { ...this.state.game };
    game["slowBuzzerMode"] = !game["slowBuzzerMode"];
    this.setState({ game });
  };

  toggleClue = (clue) => {
    const game = { ...this.state.game };
    const clueId = clue.clueId;

    if (!game[this.currentRound()].clues[clueId].body) {
      return;
    }

    if (game[this.currentRound()].clues[clueId].viewed) {
      this.resetClue(clue);
    } else {
      this.revealClue(clue);
    }
  };

  revealClue = (clue) => {
    const game = { ...this.state.game };
    const clueId = clue.clueId;
    if (clue.daily_double && !clue.daily_double_revealed) {
      this.revealDailyDouble(clue);
    } else {
      game[this.currentRound()].clues[clueId].viewed = true;
      game["currentClue"] = game[this.currentRound()].clues[clueId];

      if (this.currentRound() === "final_jeopardy") {
        const soundPlaying = { ...this.state.soundPlaying };
        soundPlaying["name"] = "finalReveal";
        this.setState({ soundPlaying });
      }

      if (!clue.daily_double) {
        this.setState({
          wager: clue.value,
        });
      }

      if (clue.media && !clue.media.includes("youtube")) {
        game["mediaStatus"] = "visible";
      } else {
        game["mediaStatus"] = "disabled";
      }

      this.setState({ game });
    }
  };

  revealDailyDouble = (clue) => {
    const clueId = clue.clueId;
    this.playDailyDoubleSound();

    const game = { ...this.state.game };
    const buzzer = { ...this.state.buzzer };

    game[this.currentRound()].clues[clueId].daily_double_revealed = true;

    if (!!buzzer["playerInControl"]) {
      buzzer["activePlayer"] = buzzer["playerInControl"];
    }
    this.setState({ game, buzzer });
    return;
  };

  resetClue = (clue) => {
    const game = { ...this.state.game };
    const clueId = clue.clueId;

    if (game[this.currentRound()].clues[clueId].daily_double) {
      game[this.currentRound()].clues[clueId].daily_double_revealed = false;
    }

    game[this.currentRound()].clues[clueId].viewed = false;

    this.setState({ game });
  };

  toggleCategory = (key) => {
    const game = { ...this.state.game };
    game[this.currentRound()].categories[key].active =
      !game[this.currentRound()].categories[key].active;
    this.setState({ game });
  };

  toggleFinalCategory = () => {
    const game = { ...this.state.game };
    const soundPlaying = { ...this.state.soundPlaying };

    game.final_jeopardy.category_revealed =
      !game.final_jeopardy.category_revealed;

    if (game.final_jeopardy.category_revealed) {
      soundPlaying["name"] = "finalReveal";
    }

    this.setState({ soundPlaying, game });
  };

  async fillBoard() {
    this.emptyBoard();
    const soundPlaying = { ...this.state.soundPlaying };
    if (this.currentRound() === "jeopardy") {
      soundPlaying["name"] = "fillBoard";
    } else {
      soundPlaying["name"] = "fillBoardDoubleJeopardy";
    }
    this.setState({ soundPlaying });
    await this.activateAllClues();
  }

  emptyBoard() {
    var clues = [...Array(30).keys()];
    const game = { ...this.state.game };

    clues.forEach((key) => {
      if (!!game[this.currentRound()].clues[key].body) {
        game[this.currentRound()].clues[key].viewed = true;
        game[this.currentRound()].clues[key].daily_double_revealed = true;
      }
    });
    this.setState({ game });
  }

  enableLogoBackground() {
    const game = { ...this.state.game };
    game.logoBackground = true;
    this.setState({ game });
  }

  disableLogoBackground() {
    const game = { ...this.state.game };
    game.logoBackground = false;
    this.setState({ game });
  }

  toggleLogoBackground() {
    const game = { ...this.state.game };
    game.logoBackground = !game.logoBackground;
    this.setState({ game });
  }

  async activateAllClues() {
    const clues = shuffle([...Array(30).keys()]);
    const theme = getTheme(this.state.theme.key);

    switch (theme.fillBoardStyle) {
      case "retro": {
        await this.activateCluesRetro(clues);
        break;
      }
      case "millionaire": {
        if (this.currentRound() === "jeopardy") {
          await this.activateCluesMillionaire();
        } else {
          await this.activateCluesMillionaireDouble();
        }
        break;
      }
      default: {
        await this.activateCluesModern(clues);
      }
    }

    this.disableLogoBackground();
  }

  async activateCluesRetro(clues) {
    if (clues.length > 0) {
      const clue = clues.pop();
      await this.activateClues([clue]);
      await this.sleep(120);
      await this.activateCluesRetro(clues);
    } else {
      return true;
    }
  }

  async activateCluesMillionaire() {
    const clues1 = [0, 5, 10, 15, 20, 25];
    const clues2 = [1, 6, 11, 16, 21, 26];
    const clues3 = [2, 7, 12, 17, 22, 27];
    const clues4 = [3, 8, 13, 18, 23, 28];
    const clues5 = [4, 9, 14, 19, 24, 29];

    await this.activateClues(clues1);
    await this.sleep(450);
    await this.activateClues(clues2);
    await this.sleep(450);
    await this.activateClues(clues3);
    await this.sleep(450);
    await this.activateClues(clues4);
    await this.sleep(450);
    await this.activateClues(clues5);
  }

  async activateCluesMillionaireDouble() {
    const clues1 = [0, 5, 10];
    const clues2 = [15, 20, 25];
    const clues3 = [1, 6, 11];
    const clues4 = [16, 21, 26];
    const clues5 = [2, 7, 12, 17, 22, 27];
    const clues6 = [3, 8, 13, 18, 23, 28];
    const clues7 = [4, 9, 14, 19, 24, 29];

    await this.sleep(150);
    await this.activateClues(clues1);
    await this.sleep(400);
    await this.activateClues(clues2);
    await this.sleep(400);
    await this.activateClues(clues3);
    await this.sleep(400);
    await this.activateClues(clues4);
    await this.sleep(400);
    await this.activateClues(clues5);
    await this.sleep(1100);
    await this.activateClues(clues6);
    await this.sleep(1100);
    await this.activateClues(clues7);
  }

  async activateCluesModern(clues) {
    if (clues.length > 0) {
      const fiveClues = [
        clues.pop(),
        clues.pop(),
        clues.pop(),
        clues.pop(),
        clues.pop(),
      ];
      await this.activateClues(fiveClues);
      await this.sleep(400);
      await this.activateCluesModern(clues);
    } else {
      return true;
    }
  }

  async activateClues(clues) {
    const game = { ...this.state.game };
    clues.forEach((key) => {
      if (!!game[this.currentRound()].clues[key].body) {
        game[this.currentRound()].clues[key].viewed = false;
        game[this.currentRound()].clues[key].daily_double_revealed = false;
      }
    });
    this.setState({ game });
  }

  toggleFinalInputEnabled() {
    const game = { ...this.state.game };
    game["finalInputEnabled"] = !game["finalInputEnabled"];
    this.setState({ game });
  }

  playIntroMusic() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = "intro";
    this.setState({ soundPlaying });
  }

  playThinkMusic() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = "thinkMusic";
    this.setState({ soundPlaying });
  }

  endGame() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = "outro";
    this.setState({ soundPlaying });
  }

  stopMusic() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = "";
    this.setState({ soundPlaying });
  }

  endRound() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = "endOfRound";
    this.setState({ soundPlaying });
  }

  players() {
    return Object.keys(this.state.players)
      .filter((key) => key < this.state.players.numberOfPlayers)
      .map((key) => this.state.players[key]);
  }

  isActivePlayer = (player) => {
    if (this.state.buzzer.activePlayer) {
      return player.index === this.state.buzzer.activePlayer.index;
    }
    return false;
  };

  isInControl = (player) => {
    if (!!this.state.buzzer.playerInControl) {
      return this.state.buzzer.playerInControl.index === player.index;
    } else {
      return false;
    }
  };

  giveControl = (player) => {
    const buzzer = { ...this.state.buzzer };
    buzzer["playerInControl"] = player;
    this.setState({ buzzer });
  };

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  render() {
    const { params } = this.props.match;
    params.gameId = params.gameId.toUpperCase();

    if (!!this.currentRound()) {
      return (
        <HostScreen
          roomCode={params.gameId}
          game={this.state.game}
          round={this.currentRound()}
          buzzer={this.state.buzzer}
          buzzes={this.state.buzzes}
          theme={this.state.theme}
          players={this.players()}
          finalReveals={this.state.finalReveals}
          allPlayers={this.state.players}
          finals={this.state.finals}
          board={this.state.game[this.currentRound()]}
          numberOfPlayers={this.state.players.numberOfPlayers}
          wager={this.state.wager}
          currentClue={this.state.game.currentClue}
          logoBackground={this.state.game.logoBackground}
          armBuzzers={this.armBuzzers.bind(this)}
          finalInputEnabled={this.state.game.finalInputEnabled}
          toggleFinalInputEnabled={this.toggleFinalInputEnabled.bind(this)}
          markCorrectResponse={this.markCorrectResponse.bind(this)}
          markIncorrectResponse={this.markIncorrectResponse.bind(this)}
          updateWager={this.updateWager.bind(this)}
          setCurrentRound={this.setCurrentRound.bind(this)}
          setCurrentPlayer={this.setCurrentPlayer.bind(this)}
          resetBuzzers={this.resetBuzzers.bind(this)}
          toggleCategory={this.toggleCategory.bind(this)}
          endGame={this.endGame.bind(this)}
          resetCurrentClue={this.resetCurrentClue.bind(this)}
          updateCategory={this.updateCategory.bind(this)}
          revealResponse={this.revealResponse.bind(this)}
          revealWager={this.revealWager.bind(this)}
          buzzerTimeout={this.state.game["buzzerTimeout"]}
          updateBuzzerTimeout={this.updateBuzzerTimeout.bind(this)}
          toggleBuzzerMode={this.toggleBuzzerMode.bind(this)}
          mediaStatus={this.state.game["mediaStatus"]}
          setMediaStatus={this.setMediaStatus.bind(this)}
          slowBuzzerMode={this.state.game.slowBuzzerMode}
          fillBoard={this.fillBoard.bind(this)}
          emptyBoard={this.emptyBoard.bind(this)}
          endRound={this.endRound.bind(this)}
          stopMusic={this.stopMusic.bind(this)}
          playThinkMusic={this.playThinkMusic.bind(this)}
          playIntroMusic={this.playIntroMusic.bind(this)}
          updateVolume={this.updateVolume.bind(this)}
          updateClue={this.updateClue.bind(this)}
          updateMedia={this.updateMedia.bind(this)}
          updateResponse={this.updateResponse.bind(this)}
          updateTheme={this.updateTheme.bind(this)}
          toggleClue={this.toggleClue.bind(this)}
          revealClue={this.revealClue.bind(this)}
          soundPlaying={this.state.soundPlaying}
          isActivePlayer={this.isActivePlayer.bind(this)}
          isInControl={this.isInControl.bind(this)}
          giveControl={this.giveControl.bind(this)}
          updateGame={this.updateGame.bind(this)}
          updatePlayers={this.updatePlayers.bind(this)}
          toggleFinalCategory={this.toggleFinalCategory.bind(this)}
          toggleLogoBackground={this.toggleLogoBackground.bind(this)}
          setScore={this.adjustScoreArbitrary.bind(this)}
        />
      );
    } else {
      return null;
    }
  }
}

export default GameLogic;
