import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Button, TextField } from "@material-ui/core";

const InputArea = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: white;
`;

const FinalJeopardy = (props) => {
  const { game, updateFinal, score } = props;

  const [wager, setWager] = useState("");
  const [response, setResponse] = useState("");
  const [wagerSubmitted, setWagerSubmitted] = useState(false);

  const inputIsEnabled = !game
    ? false
    : game.currentRound === "final_jeopardy" && game.finalInputEnabled;

  useEffect(() => {
    inputIsEnabled ? setWagerSubmitted(false) : setWagerSubmitted(true);
  }, [inputIsEnabled]);

  useEffect(() => {
    if (wagerSubmitted) {
      updateFinal(wager, response);
    }
  });

  if (inputIsEnabled && game.currentClue) {
    return (
      <InputArea>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <TextField
              label={`Response ${response.length}/80`}
              variant="outlined"
              autoFocus
              rows={2}
              value={response}
              onChange={(e) => setResponse(e.target.value.slice(0, 80))}
            />
          </Grid>
        </Grid>
      </InputArea>
    );
  }

  if (inputIsEnabled && !wagerSubmitted && !game.currentClue && score > 0) {
    return (
      <InputArea>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <TextField
              label="Wager"
              variant="outlined"
              autoFocus
              value={wager}
              onChange={(e) => {
                const updatedWager = parseInt(e.target.value);

                if (isNaN(updatedWager)) {
                  setWager("");
                } else if (updatedWager <= score) {
                  setWager(updatedWager);
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => setWagerSubmitted(true)}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </InputArea>
    );
  }

  return null;
};

export default FinalJeopardy;
