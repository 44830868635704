import React from "react";
import GameTable from "./board/GameTable";
import FinalJeopardyBoard from "./board/FinalJeopardyBoard";

const Board = (props) => {
  const {
    board,
    round,
    toggleCategory,
    toggleFinalCategory,
    toggleClue,
    finalInputEnabled,
    toggleFinalInputEnabled,
  } = props;

  if (!round) {
    return null;
  }

  if (round !== "final_jeopardy") {
    return (
      <GameTable
        board={board}
        toggleCategory={toggleCategory}
        toggleClue={toggleClue}
      />
    );
  } else {
    return (
      <FinalJeopardyBoard
        clue={board.clues[0]}
        toggleClue={toggleClue}
        categoryRevealed={board.category_revealed}
        toggleFinalCategory={toggleFinalCategory}
        finalInputEnabled={finalInputEnabled}
        toggleFinalInputEnabled={toggleFinalInputEnabled}
      />
    );
  }
};

export default Board;
