import React from "react";
import {
  Button,
  Fab,
  CardContent,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlayerControls from "./controls/PlayerControls";
import SignalControls from "./controls/SignalControls";
import MediaClueControls from "./controls/MediaClueControls";
import { Timer, Stop } from "@material-ui/icons";

const useStyles = makeStyles({
  clueText: {
    maxWidth: "20em",
    whiteSpace: "pre-line",
  },
});

const Clue = (props) => {
  const {
    clue,
    buzzer,
    buzzes,
    round,
    clueIsActive,
    revealClue,
    armBuzzers,
    markCorrectResponse,
    markIncorrectResponse,
    wager,
    updateWager,
    players,
    isActivePlayer,
    setCurrentPlayer,
    playThinkMusic,
    stopMusic,
    soundPlaying,
    finalInputEnabled,
    toggleFinalInputEnabled,
    mediaStatus,
    setMediaStatus,
  } = props;

  const classes = useStyles();

  if (!clue) {
    return (
      <CardContent>
        <Typography variant="h5">No Clue Selected</Typography>
      </CardContent>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <CardContent>
          <Typography align="right" variant="subtitle1">
            {clue.category} | ${clue.value}
          </Typography>
          <Typography className={classes.clueText} align="center" variant="h6">
            {clue.body}
          </Typography>
          <Typography align="center" variant="body1">
            {clue.response}
          </Typography>
        </CardContent>
      </Grid>

      {clue.media && clue.media.includes("youtube") && (
        <MediaClueControls
          mediaStatus={mediaStatus}
          setMediaStatus={setMediaStatus}
        />
      )}

      {round === "final_jeopardy" && (
        <CardContent>
          <Grid container item spacing={5}>
            <Grid item>
              {soundPlaying.name !== "thinkMusic" && (
                <Fab color="primary" onClick={playThinkMusic}>
                  <Timer />
                </Fab>
              )}
              {soundPlaying.name === "thinkMusic" && (
                <Fab color="secondary" onClick={stopMusic}>
                  <Stop />
                </Fab>
              )}
            </Grid>
            <Grid item>
              <Typography>
                <Switch
                  checked={finalInputEnabled}
                  onChange={toggleFinalInputEnabled}
                />
                Allow Answering
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
      {round !== "final_jeopardy" && (
        <Grid item>
          <CardContent>
            <PlayerControls
              players={players}
              buzzes={buzzes}
              isActivePlayer={isActivePlayer}
              setCurrentPlayer={setCurrentPlayer}
            />
          </CardContent>
        </Grid>
      )}
      {round !== "final_jeopardy" && (
        <Grid item>
          <CardContent>
            <SignalControls
              buzzer={buzzer}
              armBuzzers={armBuzzers}
              markCorrectResponse={markCorrectResponse}
              markIncorrectResponse={markIncorrectResponse}
              wager={wager}
              updateWager={updateWager}
            />
          </CardContent>
        </Grid>
      )}
      {!clueIsActive && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => revealClue(clue)}
          >
            Show Clue to Players
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Clue;
