import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/goat.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/goat.png";
import cardFinalJeopardy from "../images/cardFinalJeopardy/goat.jpg";
import cardDailyDouble from "../images/cardDailyDouble/goat.png";
import clueBackground from "../images/clueBackground/dark.png";
import clueBackgroundDailyDouble from "../images/clueBackgroundDailyDouble/dark.png";
import clueBackgroundMediaClue from "../images/clueBackgroundMediaClue/dark.png";
import thinkMusic from "../sounds/thinkMusic/goat.mp3";
import intro from "../sounds/intro/goat.mp3";

export default class GoatTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio: cardDailyDouble,
    cardDailyDoubleVideo: cardDailyDouble,
    cardFinalJeopardy,
    clueBackground,
    podiumBackground: clueBackground,
    clueBackgroundDailyDouble,
    clueBackgroundMediaClue,
  };
  sounds = {
    ...themeDefaults.sounds,
    thinkMusic,
    intro,
    outro: intro,
  };
  styles = { ...themeDefaults.styles };
}
