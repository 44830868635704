import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";

const MediaClueControls = (props) => {
  const { mediaStatus, setMediaStatus } = props;

  return (
    <ButtonGroup variant="outlined">
      <Button
        variant={mediaStatus === "visible" ? "contained" : "outlined"}
        onClick={() => setMediaStatus("visible")}
      >
        Play Video
      </Button>
      <Button
        variant={mediaStatus === "hidden" ? "contained" : "outlined"}
        onClick={() => setMediaStatus("hidden")}
      >
        Play Music
      </Button>
      <Button
        variant={mediaStatus === "disabled" ? "contained" : "outlined"}
        onClick={() => setMediaStatus("disabled")}
      >
        Text Only
      </Button>
    </ButtonGroup>
  );
};

export default MediaClueControls;
