import React from "react";
import sortBy from "lodash/sortBy";
import { TableContainer, Table, TableBody } from "@material-ui/core";
import Player from "./Player";

const Players = (props) => {
  const {
    players,
    round,
    buzzer,
    finals,
    finalReveals,
    isActivePlayer,
    setCurrentPlayer,
    isInControl,
    giveControl,
    setScore,
  } = props;

  const rankings = sortBy(players, ["score"])
    .map((player) => player.index)
    .reverse();

  const firstPlaceScore = players.find((p) => p.index === rankings[0]).score;
  const secondPlaceScore =
    players.length > 1 && players.find((p) => p.index === rankings[1]).score;

  const finalJeopardyRevealOrder = sortBy(Object.values(finalReveals), [
    "rank",
  ]).reverse();

  const order =
    round === "final_jeopardy"
      ? finalJeopardyRevealOrder.map((player) => player.index)
      : players.map((player) => player.index);

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {order.map((index) => {
            const player = players.find((p) => p.index === index);
            const ranking = rankings.indexOf(player.index);
            let distanceFromFirst = firstPlaceScore - player.score;
            let distanceAhead = secondPlaceScore
              ? player.score - secondPlaceScore
              : 0;

            return (
              <Player
                key={player.index}
                ranking={ranking}
                distanceFromFirst={distanceFromFirst}
                distanceAhead={distanceAhead}
                round={round}
                buzzer={buzzer}
                player={player}
                final={finals[player.index]}
                finalRevealed={finalReveals[player.index]?.revealed || false}
                isActivePlayer={isActivePlayer}
                setCurrentPlayer={setCurrentPlayer}
                isInControl={isInControl}
                giveControl={giveControl}
                setScore={setScore}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Players;
