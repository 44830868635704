import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/millionaire.jpg";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/millionaire.jpg";
import cardFinalJeopardy from "../images/cardFinalJeopardy/millionaire.jpg";
import cardDailyDouble from "../images/cardDailyDouble/millionaire.jpg";
import clueBackground from "../images/clueBackground/dark.png";
import clueBackgroundDailyDouble from "../images/clueBackgroundDailyDouble/dark.png";
import clueBackgroundMediaClue from "../images/clueBackgroundMediaClue/dark.png";
import stageBackground from "../images/stageBackground/millionaire.jpg";
import fillBoard from "../sounds/fillBoard/millionaire.m4a";
import fillBoardDoubleJeopardy from "../sounds/fillBoardDoubleJeopardy/millionaire.mp3";
import timeOut from "../sounds/timeOut/millionaire.m4a";
import dailyDouble from "../sounds/dailyDouble/millionaire.m4a";
import finalReveal from "../sounds/finalReveal/millionaire.mp3";
import thinkMusic from "../sounds/thinkMusic/millionaire.mp3";
import intro from "../sounds/intro/millionaire.mp3";
import outro from "../sounds/outro/millionaire.mp3";
import endOfRound from "../sounds/endOfRound/millionaire.mp3";

export default class MillionaireTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio: cardDailyDouble,
    cardDailyDoubleVideo: cardDailyDouble,
    cardFinalJeopardy,
    clueBackground,
    stageBackground,
    podiumBackground: clueBackground,
    clueBackgroundDailyDouble,
    clueBackgroundMediaClue,
  };
  sounds = {
    ...themeDefaults.sounds,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoardDoubleJeopardy,
    dailyDouble,
    finalReveal,
    thinkMusic,
    timeOut,
    endOfRound,
    intro,
    outro,
  };
  styles = { ...themeDefaults.styles };
  fillBoardStyle = "millionaire";
  reverseClues = true;
}
