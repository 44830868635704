import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { base } from "../base";
import { defaultGameState } from "../helpers/applicationHelper";

import backgroundTexture from "../images/bgtexture.png";

const StyledMenu = styled.div`
  background-image: ${"url(" + backgroundTexture + ")"};
  background-repeat: repeat;
  height: 100vh;
`;

const Form = styled.div`
  position: relative;
  display: block;
  top: 40%;
  text-align: center;
  width: 100%;
  margin: auto;
`;

const PlayerSelect = styled.select`
  font-size: 6vw;
  width: 75%;
`;

const PlayerOption = styled.option``;

const JoinButton = styled.button`
  font-size: 6vw;
  display: block;
  margin: 3vw auto;
  padding: 3vw;
`;

const RoutedJoinButton = withRouter(({ history, roomCode, player }) => (
  <JoinButton
    onClick={() => {
      if (player != null) {
        history.push(`/${roomCode}/buzz/${parseInt(player) + 1}`);
      }
    }}
  >
    Join
  </JoinButton>
));

class Join extends Component {
  state = defaultGameState();

  componentDidMount() {
    const { params } = this.props.match;
    params.gameId = params.gameId.toUpperCase();

    this.setState({ roomCode: params.gameId, selectedPlayer: null });

    base.syncDoc(`games/${params.gameId}`, {
      context: this,
      state: "game",
    });

    base.syncDoc(`players/${params.gameId}`, {
      context: this,
      state: "players",
    });
  }

  render() {
    return (
      <StyledMenu>
        <Form>
          <PlayerSelect
            onChange={(event) => {
              this.setState({ selectedPlayer: event.target.value });
            }}
          >
            <PlayerOption value={null}>Select a player</PlayerOption>
            {Object.keys(this.state.players).map((key) => {
              if (key < this.state.players.numberOfPlayers) {
                return (
                  <PlayerOption value={key}>
                    {this.state.players[key].name}
                  </PlayerOption>
                );
              } else {
                return null;
              }
            })}
          </PlayerSelect>
          <RoutedJoinButton
            roomCode={this.state.roomCode}
            player={this.state.selectedPlayer}
          />
        </Form>
      </StyledMenu>
    );
  }
}

export default Join;
