import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Switch,
} from "@material-ui/core";
import ClueCell from "./ClueCell";

const FinalJeopardyBoard = (props) => {
  const {
    clue,
    toggleClue,
    categoryRevealed,
    toggleFinalCategory,
    finalInputEnabled,
    toggleFinalInputEnabled,
  } = props;

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>
              <Switch
                checked={categoryRevealed}
                onChange={() => toggleFinalCategory()}
              />
              <Typography>{clue.category}</Typography>
            </TableCell>
            <ClueCell clue={clue} toggleClue={toggleClue} />
            <TableCell>
              <Typography>
                <Switch
                  checked={finalInputEnabled}
                  onChange={toggleFinalInputEnabled}
                />
                Allow Wagering
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <>
        <Typography>Players are now sorted in reverse rank order.</Typography>
        <Typography>
          Reveal responses from <strong>top to bottom.</strong>
        </Typography>
      </>
    </TableContainer>
  );
};

export default FinalJeopardyBoard;
