import React, { Component } from "react";
import { base } from "../base";
import { TextField, Button } from "@material-ui/core";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const StyledMenu = styled.div`
  height: 100vh;
`;

const JoinFormWrapper = styled.div`
  position: relative;
  top: 30%;
  vertical-align: middle;
  text-align: center;
  input {
    display: block;
    text-align: center;
    font-size: 50px;
    width: 4em;
    text-transform: uppercase;
  }
  button {
    display: block;
    font-size: 30px;
    margin: 25px auto 25px auto;
  }
`;

const JoinButton = withRouter(({ history, roomCode }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={() => {
      base
        .get(`players/${roomCode}`, {
          context: this,
        })
        .then((data) => {
          history.push(`/${roomCode}/join`);
        })
        .catch((err) => {
          alert(`Room not found! Please try again.`);
        });
    }}
  >
    Join Game
  </Button>
));

class Home extends Component {
  state = {
    roomCode: null,
  };

  render() {
    return (
      <StyledMenu>
        <JoinFormWrapper>
          <TextField
            label="Room Code"
            variant="outlined"
            onChange={(e) =>
              this.setState({ roomCode: e.target.value.toUpperCase() })
            }
          />
          <JoinButton roomCode={this.state.roomCode} />
        </JoinFormWrapper>
      </StyledMenu>
    );
  }
}

export default Home;
