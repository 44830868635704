import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CreateGame from "./CreateGame";
import WatchGame from "./WatchGame";
import Home from "./Home";
import Join from "./Join";
import GameWithAudio from "./play/GameWithAudio";
import GameLogic from "./host/GameLogic";
import SignalingDevice from "./signalingDevice/SignalingDevice";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/host" component={CreateGame} />
      <Route exact path="/watch" component={WatchGame} />
      <Route path="/:gameId/join" component={Join} />
      <Route path="/:gameId/watch" component={GameWithAudio} />
      <Route path="/:gameId/host" component={GameLogic} />
      <Route path="/:gameId/buzz/:playerId" component={SignalingDevice} />
      <Route component={Home} />
    </Switch>
  </BrowserRouter>
);

export default Router;
