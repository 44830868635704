import React from "react";
import { Switch, Typography, TableCell } from "@material-ui/core";

const CategoryCell = (props) => {
  const { category, toggleCategory, index } = props;

  return (
    <TableCell>
      <Typography variant="caption">
        <Switch
          checked={category.active}
          onChange={() => toggleCategory(index)}
        />
        {category.name}
      </Typography>
    </TableCell>
  );
};

export default CategoryCell;
