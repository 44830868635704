import React, { Component } from "react";
import styled from "styled-components";
import ContainerDimensions from "react-container-dimensions";

import CreateIcon from "@material-ui/icons/Create";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import ClearIcon from "@material-ui/icons/Clear";

import { base } from "../../base";

import podiumSpacer from "../../images/podiumSpacer/blue.png";
import backgroundTexture from "../../images/bgtexture.png";

import FinalJeopardy from "./FinalJeopardy";
import CanvasDraw from "react-canvas-draw";

const PageWrapper = styled.div`
  background-color: #222;
  height: 100vh;
  overflow: scroll;
`;

const StyledSignalingDevice = styled.div`
  background-repeat: repeat;
  background-image: url("${backgroundTexture}");
  max-width: 600px;
  min-width: 300px;

  margin: auto;
  border: 10px solid black;

  p {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;

const ScoreSection = styled.div`
  height: 150px;
  border-bottom: 10px solid black;
`;

const Score = styled.p`
  font-family: "Zurich";
  padding-top: 20px;
  color: ${(props) => (props.score < 0 ? "red" : "white")};
  font-size: 4.5rem;
  text-shadow: 0.3rem 0.3rem 0px black;
  margin: auto;
  width: 100%;
  text-align: center;
  text-shadow: ${[...Array(30).keys()]
    .map((value) => `${value / 10}px ${value / 10}px 0 black`)
    .join(", ")};

  .currency {
    ${(props) =>
      props.smallCurrency &&
      `
      font-size: 3rem;
      position: relative;
      bottom: 10px;
      margin-right: 10px;
      `}
  }
`;

const TopSpacerSection = styled.div`
  height: 150px;
  border-bottom: 10px solid black;
  background-image: ${(props) =>
    props.canBuzz() ? "" : `url("${podiumSpacer}")`};
  background-color: ${(props) => (props.canBuzz() ? "red" : "")};
  background-size: cover;
  background-position: right;
`;

const SpacerSection = styled.div`
  height: 100%;
  .left-right-spacer {
    height: 100%;
    width: 100%;
    box-shadow: inset ${(props) => (props.isActivePlayer ? "100vw" : "0px")} 0 0
      0 #fff;
    transition: box-shadow ${(props) => (props.isActivePlayer ? "0.25s" : "0s")}
      linear;
  }
  .right-left-spacer {
    height: 100%;
    width: 100%;
    box-shadow: inset ${(props) => (props.isActivePlayer ? "-100vw" : "0px")} 0
      0 0 #fff;
    transition: box-shadow ${(props) => (props.isActivePlayer ? "0.25s" : "0s")}
      linear;
  }
`;

const SignatureSection = styled.div`
  width: 100%;
`;

const SignatureControlSection = styled.div`
  text-shadow: 3px 3px 0px black;
  font-size: 2rem;
`;

const SignatureButtons = styled.div`
  display: inline;
  text-align: right;
  float: right;
  margin: 1rem;
`;

const SignatureButton = styled.div`
  display: inline;
  color: white;
  margin: 1rem;
`;

const Name = styled.p`
  display: inline;
  font-family: "SueEllen";
  color: white;
  margin: 1rem 2rem;
`;

const Buzz = styled.p`
  font-family: "Zurich";
  color: white;
  font-size: 3rem;
  margin: auto;
  padding-top: 40px;
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 2px black;
`;

const BuzzerText = (props) => {
  const { canBuzz, speed } = props;

  if (canBuzz()) {
    return "!";
  } else if (speed) {
    return `${speed / 1000}s`;
  } else {
    return "";
  }
};

class SignalingDevice extends Component {
  state = {
    buzzer: {},
    players: {},
    game: {},
    locked: false,
    armedAt: null,
    buzzed: false,
    lockout: null,
    signing: false,
    signature: { data: "" },
  };

  componentDidMount() {
    const { params } = this.props.match;
    params.gameId = params.gameId.toUpperCase();

    base.syncDoc(`buzzers/${params.gameId}`, {
      context: this,
      state: "buzzer",
    });

    base.syncDoc(`signatures/${params.gameId}/players/${params.playerId - 1}`, {
      context: this,
      state: "signature",
    });

    base.syncDoc(`players/${params.gameId}`, {
      context: this,
      state: "players",
    });

    base.syncDoc(`games/${params.gameId}`, {
      context: this,
      state: "game",
    });

    base.syncDoc(`finals/${params.gameId}/data/${params.playerId}`, {
      context: this,
      state: "final",
    });

    document.addEventListener("keydown", (event) => {
      if (event.code === "Space" || event.code === "Enter") {
        this.ringIn();
      }
    });

    this.canvas = React.createRef();
  }

  componentDidUpdate() {
    if (this.buzzerIsArmed() && !this.state.armedAt) {
      this.setState({ armedAt: Date.now(), speed: null, buzzed: false });
    } else if (!this.buzzerIsArmed() && !!this.state.armedAt) {
      if (this.player() && !this.isActivePlayer()) {
        this.setState({ armedAt: null, buzzed: false });
      } else {
        this.setState({ armedAt: null });
      }
    }

    if (!!this.state.speed && !this.state.game.currentClue) {
      this.setState({ speed: null });
    }
  }

  handleSaveCanvas() {
    this.setSignature(this.canvas.getSaveData());
    this.toggleSigningView();
  }

  buzzerIsArmed = () => {
    return this.state.buzzer.armed;
  };

  playerHasBuzzed = () => {
    return !!this.state.buzzer.activePlayer;
  };

  isActivePlayer = () => {
    if (!!this.player() && !!this.state.buzzer.activePlayer) {
      return this.player().index === this.state.buzzer.activePlayer.index;
    }
    return false;
  };

  toggleSigningView = () => {
    this.setState({ signing: !this.state.signing });
  };

  setSignature = (data) => {
    this.setState({ signature: { data } });
  };

  score() {
    return this.player()
      .score.toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      .replace("-", "");
  }

  ringIn = async () => {
    if (!this.buzzerIsArmed() || this.state.locked || this.player().locked) {
      if (!this.buzzerIsArmed()) {
        this.lockBuzzer();
      }
    } else {
      const speed = Date.now() - this.state.armedAt;
      if (!this.playerHasBuzzed() && !this.state.buzzed) {
        this.setState({ buzzed: true, speed: speed });
        const { params } = this.props.match;
        base.addToCollection(
          `mobileBuzzes/${params.gameId}/buzzes`,
          {
            speed: speed,
            player: this.player(),
          },
          speed.toString()
        );
      }
    }
  };

  canBuzz() {
    return !this.state.buzzed && this.buzzerIsArmed() && !this.player().locked;
  }

  lockBuzzer() {
    if (!this.isActivePlayer()) {
      clearTimeout(this.state.lockout);
      this.setState({ locked: true });
      const lockout = setTimeout(() => this.setState({ locked: false }), 250);
      this.setState({ lockout });
    }
  }

  player() {
    const { params } = this.props.match;
    return this.state.players[params.playerId - 1];
  }

  updateFinal(wager, response) {
    const final = { ...this.state.final };
    if (wager !== "") {
      final["wager"] = wager;
    }
    if (response !== "") {
      final["response"] = response;
    }
    this.setState({ final });
  }

  render() {
    if (
      !!this.player() &&
      this.state.players.numberOfPlayers > this.player().index
    ) {
      const currency = this.state.game.currency || "";
      const score = this.player().score;

      return (
        <PageWrapper canBuzz={this.canBuzz.bind(this)}>
          <StyledSignalingDevice>
            <ScoreSection>
              <Score score={score} smallCurrency={currency.length > 1}>
                {score < 0 && "-"}
                <span className="currency">{currency}</span>
                {this.score()}
              </Score>
            </ScoreSection>
            <TopSpacerSection
              canBuzz={this.canBuzz.bind(this)}
              onClick={this.ringIn.bind(this)}
            >
              <SpacerSection isActivePlayer={this.isActivePlayer()}>
                <div className="left-right-spacer">
                  <div className="right-left-spacer">
                    <FinalJeopardy
                      game={this.state.game}
                      updateFinal={this.updateFinal.bind(this)}
                      score={this.player().score}
                    />
                    <Buzz>
                      <BuzzerText
                        canBuzz={this.canBuzz.bind(this)}
                        speed={this.state.speed}
                      />
                    </Buzz>
                  </div>
                </div>
              </SpacerSection>
            </TopSpacerSection>
            <SignatureSection>
              <ContainerDimensions>
                {({ width }) => (
                  <CanvasDraw
                    ref={(canvasDraw) => (this.canvas = canvasDraw)}
                    brushColor="white"
                    canvasWidth={width}
                    canvasHeight={width * 0.62}
                    hideGrid={true}
                    lazyRadius={0}
                    immediateLoading={true}
                    disabled={!this.state.signing}
                    saveData={this.state.signature?.data || ""}
                    backgroundColor="#00000000"
                  />
                )}
              </ContainerDimensions>
            </SignatureSection>
            <SignatureControlSection>
              <Name>{this.player().name}</Name>
              {this.state.signing ? (
                <SignatureButtons>
                  <SignatureButton onClick={this.handleSaveCanvas.bind(this)}>
                    <SaveIcon />
                  </SignatureButton>
                  <SignatureButton onClick={this.canvas.undo}>
                    <UndoIcon />
                  </SignatureButton>
                  <SignatureButton onClick={this.canvas.clear}>
                    <ClearIcon />
                  </SignatureButton>
                </SignatureButtons>
              ) : (
                <SignatureButtons>
                  <SignatureButton onClick={this.toggleSigningView.bind(this)}>
                    <CreateIcon />
                  </SignatureButton>
                </SignatureButtons>
              )}
            </SignatureControlSection>
          </StyledSignalingDevice>
        </PageWrapper>
      );
    } else {
      return null;
    }
  }
}

export default SignalingDevice;
