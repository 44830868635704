import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/s36.jpg";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/s36.png";
import cardDailyDouble from "../images/cardDailyDouble/s36.png";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/s36.png";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/s36.png";
import cardFinalJeopardy from "../images/cardFinalJeopardy/s36.png";

export default class Season36Theme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    cardFinalJeopardy,
  };
  sounds = themeDefaults.sounds;
  styles = { ...themeDefaults.styles };
}
