import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Slider } from "@material-ui/core";
import { VolumeUp } from "@material-ui/icons";

const useStyles = makeStyles({
  slider: {
    width: "50%",
  },
});

const VolumeSlider = (props) => {
  const classes = useStyles();

  const { volume, updateVolume } = props;
  const [currentValue, setCurrentValue] = useState(
    typeof volume === "number" ? volume : 50
  );

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <VolumeUp />
        </Grid>
        <Grid item xs>
          <Slider
            className={classes.slider}
            value={currentValue}
            onChange={(_event, value) => setCurrentValue(value)}
            onChangeCommitted={(_event, value) => updateVolume(value)}
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={100}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VolumeSlider;
