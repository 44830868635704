import querystring from "querystring";

export const embedUrl = (url) => {
  const params = { autoplay: 1, ...querystring.parse(url.split("?")[1]) };
  const videoId = params["v"];
  delete params["v"];
  return `https://www.youtube.com/embed/${videoId}?${querystring.stringify(
    params
  )}`;
};

export const youtubeParams = (url) => {
  const params = { ...querystring.parse(url.split("?")[1]) };

  return {
    id: params["v"],
    start: params["start"],
    end: params["end"],
    autoplay: params["autoplay"] === "1",
    audioOnly: params["audioonly"] === "1",
  };
};
