import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const RoundSettings = (props) => {
  const { round, setCurrentRound, setIsOpen } = props;

  return (
    <FormControl variant="outlined">
      <InputLabel>Round</InputLabel>
      <Select
        label="Round"
        value={round}
        onChange={(event) => {
          setCurrentRound(event.target.value);
          setIsOpen(false);
        }}
      >
        <MenuItem value="jeopardy">Jeopardy</MenuItem>
        <MenuItem value="double_jeopardy">Double Jeopardy</MenuItem>
        <MenuItem value="triple_jeopardy">Triple Jeopardy</MenuItem>
        <MenuItem value="final_jeopardy">Final Jeopardy</MenuItem>
      </Select>
    </FormControl>
  );
};

export default RoundSettings;
