import React, { Component } from "react";
import { base } from "../../base";
import getTheme from "../../themes/getTheme";
import Game from "./Game";
import AudioPlayer from "../AudioPlayer";

class GameWithAudio extends Component {
  state = {};

  componentDidMount() {
    const { params } = this.props.match;
    const gameId = params.gameId.toUpperCase();

    base.syncDoc(`theme/${gameId}`, {
      context: this,
      state: "theme",
    });
  }

  resetSound() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = null;
    this.setState({ soundPlaying });
  }

  render() {
    if (!!this.state.theme) {
      const theme = getTheme(this.state.theme.key);
      const gameId = this.props.match.params.gameId.toUpperCase();

      return (
        <>
          <Game theme={theme} gameId={gameId} />
          <AudioPlayer theme={theme} gameId={gameId} />
        </>
      );
    } else {
      return <div></div>;
    }
  }
}

export default GameWithAudio;
