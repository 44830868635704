import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledTimer = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1.11vw);
  height: 0.6vw;
  width: 10vw;
  background-color: transparent;
  border-bottom: 0.1vw solid black;
  position: absolute;
  top: -0.7vw;
`;

const TimerCell = styled.div`
  border: 0.1vw solid #ddd;
  background-color: ${(props) => (props.light ? "red" : "#444")};
`;

const Timer = (props) => {
  const { isBuzzed } = props;
  const [secondsRemaining, setSecondsRemaining] = useState(5);

  useEffect(() => {
    if (!isBuzzed) {
      setSecondsRemaining(5);
    } else if (secondsRemaining > 0) {
      setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
    }
  }, [isBuzzed, secondsRemaining, setSecondsRemaining]);

  return (
    <StyledTimer>
      <TimerCell light={isBuzzed && secondsRemaining >= 5} />
      <TimerCell light={isBuzzed && secondsRemaining >= 4} />
      <TimerCell light={isBuzzed && secondsRemaining >= 3} />
      <TimerCell light={isBuzzed && secondsRemaining >= 2} />
      <TimerCell light={isBuzzed && secondsRemaining >= 1} />
      <TimerCell light={isBuzzed && secondsRemaining >= 2} />
      <TimerCell light={isBuzzed && secondsRemaining >= 3} />
      <TimerCell light={isBuzzed && secondsRemaining >= 4} />
      <TimerCell light={isBuzzed && secondsRemaining >= 5} />
    </StyledTimer>
  );
};

export default Timer;
