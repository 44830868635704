import React from "react";
import { Box, Typography } from "@material-ui/core";
import { MainClues, FinalJeopardyClue } from "./Clues";

const EditBoard = (props) => {
  const { game } = props;

  return (
    <Box>
      <Typography variant="h4">Jeopardy</Typography>
      <MainClues game={game} round="jeopardy" />
      <Typography variant="h4">Double Jeopardy</Typography>
      <MainClues game={game} round="double_jeopardy" />
      <Typography variant="h4">Triple Jeopardy</Typography>
      <MainClues game={game} round="triple_jeopardy" />
      <Typography variant="h4">Final Jeopardy</Typography>
      <FinalJeopardyClue />
    </Box>
  );
};

export default EditBoard;
