import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import { CheckCircle, HighlightOff } from "@material-ui/icons";

const JudgeControls = (props) => {
  const { markCorrectResponse, markIncorrectResponse } = props;

  return (
    <ButtonGroup variant="contained">
      <Button
        onClick={() => {
          markCorrectResponse();
        }}
      >
        <CheckCircle /> <pre>[+]</pre>
      </Button>
      <Button
        onClick={() => {
          markIncorrectResponse();
        }}
      >
        <HighlightOff /> <pre>[-]</pre>
      </Button>
    </ButtonGroup>
  );
};

export default JudgeControls;
