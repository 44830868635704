import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/sports.png";
import cardDailyDouble from "../images/cardDailyDouble/sports.png";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/sports.jpg";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/sports.jpg";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/sports.jpg";
import cardFinalJeopardy from "../images/cardFinalJeopardy/sports.jpg";
import podiumSpacer from "../images/podiumSpacer/sports.png";
import stageBackground from "../images/stageBackground/sports.png";
import podiumsBackground from "../images/podiumsBackground/sports.png";

import thinkMusic from "../sounds/thinkMusic/sports.mp3";
import intro from "../sounds/intro/sports.mp3";
import outro from "../sounds/outro/sports.mp3";
import fillBoard from "../sounds/fillBoard/2008.wav";

export default class SportsTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardFinalJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    podiumSpacer,
    stageBackground,
    podiumsBackground,
  };
  sounds = {
    ...themeDefaults.sounds,
    thinkMusic,
    intro,
    outro,
    fillBoard,
  };
  styles = { ...themeDefaults.styles };
}
