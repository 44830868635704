import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import backgroundTexture from "../images/bgtexture.png";

const StyledMenu = styled.div`
  background-image: ${"url(" + backgroundTexture + ")"};
  background-repeat: repeat;
  height: 100vh;
  color: white;
  vertical-align: middle;
`;

const FormWrapper = styled.div`
  position: relative;
  display: block;
  vertical-align: middle;
  text-align: center;
  padding: 25px;
  top: 20%;
  h2 {
    margin: 0;
  }
  input {
    width: 70vw;
    padding: 10px;
    text-align: center;
    font-size: 10vw;
    text-transform: uppercase;
  }
  button {
    font-size: 5vw;
  }
  * {
    display: block;
    margin: 5px auto 5px auto;
  }
`;

const Heading = styled.p`
  text-align: center;
  font-family: "Swiss 911";
  color: "white";
  font-size: 8vw;
  text-shadow: 0.3rem 0.3rem 0px black; ;
`;

const WatchGameButton = withRouter(({ history, roomCode }) => (
  <button
    onClick={async () => {
      history.push(`/${roomCode}/watch`);
    }}
  >
    START
  </button>
));

class WatchGame extends Component {
  state = {
    roomCode: null,
  };

  render() {
    return (
      <StyledMenu>
        <FormWrapper>
          <Heading>Join the game at jparty.tv</Heading>
          <div>
            <input
              onChange={(e) =>
                this.setState({ roomCode: e.target.value.toUpperCase() })
              }
              placeholder="Room Code"
            />
            <WatchGameButton roomCode={this.state.roomCode} />
          </div>
        </FormWrapper>
      </StyledMenu>
    );
  }
}

export default WatchGame;
