import React, { Component } from "react";
import { base } from "../base";
import Sound from "react-sound";

class AudioPlayer extends Component {
  state = {};

  componentDidMount() {
    const { gameId } = this.props;

    base.syncDoc(`soundPlaying/${gameId}`, {
      context: this,
      state: "soundPlaying",
    });
  }

  resetSound() {
    const soundPlaying = { ...this.state.soundPlaying };
    soundPlaying["name"] = null;
    this.setState({ soundPlaying });
  }

  render() {
    const { theme } = this.props;
    const name = this.state.soundPlaying?.name;
    const volume = this.state.soundPlaying?.volume || 50;

    return (
      <Sound
        url={theme.sounds[name] || ""}
        playStatus={Sound.status.PLAYING}
        autoLoad={true}
        onFinishedPlaying={this.resetSound.bind(this)}
        volume={volume}
      />
    );
  }
}

export default AudioPlayer;
