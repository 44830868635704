import React from "react";
import { TextField } from "@material-ui/core";

const WagerControls = (props) => {
  const { updateWager, wager } = props;

  return (
    <TextField
      label="Wager"
      variant="outlined"
      onChange={(e) => updateWager(e.target.value)}
      id="wager"
      value={wager}
    />
  );
};

export default WagerControls;
