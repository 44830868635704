import React from "react";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { Field } from "formik";

const EditClueValues = (props) => {
  const { clueValues, setClueValues, updateClueValues } = props;

  const updateValue = (round, index, value) => {
    const clonedClueValues = clueValues;
    clonedClueValues[round][index] = value;
    setClueValues(clonedClueValues);
  };

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography variant="h4">Clue Values</Typography>
        <Typography>
          Currency: <Field type="text" maxLength="7" name={`game[currency]`} />
        </Typography>
        <Typography variant="h6">Jeopardy</Typography>
        {[...Array(5).keys()].map((index) => (
          <TextField
            type="number"
            label={`Clue ${index + 1}`}
            defaultValue={clueValues["jeopardy"][index]}
            onChange={(e) => updateValue("jeopardy", index, e.target.value)}
          />
        ))}
        <Typography variant="h6">Double Jeopardy</Typography>
        {[...Array(5).keys()].map((index) => (
          <TextField
            type="number"
            label={`Clue ${index + 1}`}
            defaultValue={clueValues["double_jeopardy"][index]}
            onChange={(e) =>
              updateValue("double_jeopardy", index, e.target.value)
            }
          />
        ))}
        <Typography variant="h6">Triple Jeopardy</Typography>
        {[...Array(5).keys()].map((index) => (
          <TextField
            type="number"
            label={`Clue ${index + 1}`}
            defaultValue={clueValues["triple_jeopardy"][index]}
            onChange={(e) =>
              updateValue("triple_jeopardy", index, e.target.value)
            }
          />
        ))}
        <Button onClick={updateClueValues}>Update All Values</Button>
      </Grid>
    </Grid>
  );
};

export default EditClueValues;
