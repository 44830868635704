import React, { Component } from "react";
import styled from "styled-components";

import { base } from "../../base";
import { defaultGameState } from "../../helpers/applicationHelper";
import Board from "./Board";
import Podium from "./Podium";

const AppContainer = styled.div`
  height: 100vh;
  background-color: black;
`;

const StyledGame = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
`;

const FullBoard = styled.div`
  width: 100%;
  background-color: black;
  background-image: ${(props) => `url("${props.background}")`};
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 80%;
  height: 80%;
  width: 100%;
`;

const Podiums = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: ${(props) =>
    `repeat(${props.numberOfPlayers}, ${20 - props.numberOfPlayers}%)`};
  background-color: black;
  background-image: ${(props) => `url("${props.background}")`};
  background-size: contain;
  text-align: center;
  font-family: "Swiss 911";
  overflow: visible;
  position: absolute;
  top: 80%;
  bottom: 100%;
  height: 20%;
  width: 100%;
`;

class Game extends Component {
  state = defaultGameState();

  componentDidMount() {
    const { gameId } = this.props;

    base.syncDoc(`games/${gameId}`, {
      context: this,
      state: "game",
    });

    base.syncDoc(`buzzers/${gameId}`, {
      context: this,
      state: "buzzer",
    });

    base.syncDoc(`players/${gameId}`, {
      context: this,
      state: "players",
    });

    base.syncDoc(`finals/${gameId}/data/activeResponse`, {
      context: this,
      state: "activeResponse",
    });
  }

  buzzerIsArmed = () => {
    return this.state.buzzer.armed;
  };

  playerHasBuzzed = () => {
    return !!this.state.buzzer.activePlayer;
  };

  isActivePlayer = (player) => {
    if (this.state.buzzer.activePlayer) {
      return player.index === this.state.buzzer.activePlayer.index;
    }
    return false;
  };

  isInControl = (player) => {
    if (this.state.buzzer.playerInControl) {
      return player.index === this.state.buzzer.playerInControl.index;
    }
    return false;
  };

  currentRound() {
    if (!this.state.game) {
      return "";
    } else {
      return this.state.game.currentRound;
    }
  }

  setMediaStatus(status) {
    const game = { ...this.state.game };
    game.mediaStatus = status;
    this.setState({ game });
  }

  render() {
    if (!!this.currentRound()) {
      const theme = this.props.theme;

      return (
        <AppContainer>
          <StyledGame>
            <FullBoard background={theme.images.stageBackground}>
              <Board
                theme={theme}
                activeClue={this.state.game.currentClue}
                mediaStatus={this.state.game.mediaStatus}
                setMediaStatus={this.setMediaStatus.bind(this)}
                currency={this.state.game.currency}
                board={this.state.game[this.currentRound()]}
                buzzer={this.state.buzzer}
                activeResponse={this.state.activeResponse}
                logoBackground={this.state.game.logoBackground}
              />
            </FullBoard>
            <Podiums
              numberOfPlayers={this.state.players.numberOfPlayers}
              background={theme.images.podiumsBackground}
            >
              {Object.keys(this.state.players).map(
                (key) =>
                  key < this.state.players.numberOfPlayers && (
                    <Podium
                      key={key}
                      theme={theme}
                      timerEnabled={
                        this.state.game.currentClue &&
                        !this.state.game.currentClue.daily_double &&
                        !this.currentRound() !== "final_jeopardy"
                      }
                      gameId={this.props.gameId}
                      player={this.state.players[key]}
                      currency={this.state.game.currency}
                      numberOfPlayers={this.state.players.numberOfPlayers}
                      buzzer={this.state.buzzer}
                      isBuzzed={this.isActivePlayer(this.state.players[key])}
                      isInControl={this.isInControl(this.state.players[key])}
                    />
                  )
              )}
            </Podiums>
          </StyledGame>
        </AppContainer>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Game;
