import React from "react";
import { IconButton, Typography, TableCell } from "@material-ui/core";
import {
  Subject,
  Image,
  PlayCircleFilled,
  Star,
  Refresh,
  Cancel,
} from "@material-ui/icons";

const ClueIcon = (props) => {
  const { clue } = props;
  if (!clue.body) {
    return <Cancel />;
  } else if (clue.viewed) {
    return <Refresh />;
  } else if (clue.daily_double && !clue.daily_double_revealed) {
    return <Star />;
  } else if (clue.media && clue.media.includes("youtube")) {
    return <PlayCircleFilled />;
  } else if (clue.media) {
    return <Image />;
  } else {
    return <Subject />;
  }
};

const ClueCell = (props) => {
  const { clue, toggleClue } = props;

  return (
    <TableCell>
      <Typography
        variant="button"
        color={clue.daily_double ? "secondary" : "initial"}
      >
        {!clue.viewed && clue.value}
        <IconButton onClick={() => toggleClue(clue)} size="small">
          <ClueIcon clue={clue} />
        </IconButton>
      </Typography>
    </TableCell>
  );
};

export default ClueCell;
