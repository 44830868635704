import React, { useEffect } from "react";
import { Grid, Typography, Button, CardContent } from "@material-ui/core";
import JudgeControls from "./controls/JudgeControls";
import WagerControls from "./controls/WagerControls";

const FinalJeopardyReveal = (props) => {
  const {
    clue,
    activePlayer,
    finals,
    revealWager,
    revealResponse,
    wager,
    updateWager,
    markCorrectResponse,
    markIncorrectResponse,
  } = props;

  useEffect(() => {
    if (activePlayer) {
      updateWager(finals[activePlayer.index].wager || 0);
    }
  });

  if (!activePlayer) {
    return null;
  }

  return (
    <CardContent>
      <Grid container direction="column" spacing={3} alignItems="center">
        {clue && (
          <>
            <Grid item>
              {clue.category}: {clue.body}: <strong>{clue.response}</strong>
            </Grid>
          </>
        )}
        <Grid item>
          <Typography variant="h5">{activePlayer.name}</Typography>
        </Grid>
        <Grid item>
          <Typography>
            <strong>
              {finals[activePlayer.index].response || "NO RESPONSE"}
            </strong>
          </Typography>
        </Grid>
        <Grid item>
          <WagerControls wager={wager} updateWager={updateWager} />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => revealResponse(activePlayer)}
          >
            Reveal Response
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => revealWager(activePlayer)}>
            Reveal Wager
          </Button>
        </Grid>
        <Grid item>
          <JudgeControls
            markCorrectResponse={markCorrectResponse}
            markIncorrectResponse={markIncorrectResponse}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default FinalJeopardyReveal;
