import React, { Component } from "react";

import styled from "styled-components";

const Background = styled.div`
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  background-image: ${(props) => `url("${props.background}")`};
  background-size: cover;
  background-position: center;
  height: 39vw;
`;

const FullscreenCategoryName = styled.div`
  color: white;
  font-family: "Swiss 921";
  text-transform: uppercase;
  text-shadow: ${[...Array(40).keys()]
    .map((value) => `${value / 100}vw ${value / 100}vw 0vw black`)
    .join(", ")};
  font-size: ${(props) => (props.small ? "8vw" : "10vw")};
  line-height: ${(props) => (props.small ? "7.5vw" : "9.5vw")};
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 50%;
  margin-right: -33.3%;
  transform: translate(-50%, -50%);
  letter-spacing: 0.1vw;
`;

const Response = styled.div`
  color: white;
  font-family: "SueEllen";
  text-shadow: 0.3vw 0.3vw 0.3vw #000;
  font-size: 5vw;
  line-height: 6vw;
  text-align: center;
  position: absolute;
  top: 25%;
  transform: translate(0, -50%);
  left: 50%;
  margin-right: -40%;
  margin-top: 5%;
  transform: translate(-50%, -50%);
  letter-spacing: 0.1vw;
  word-wrap: anywhere;
`;

const Wager = styled.div`
  color: white;
  font-family: "SueEllen";
  text-shadow: 0.3vw 0.3vw 0.3vw #000;
  font-size: 8vw;
  text-align: center;
  position: absolute;
  top: 80%;
  transform: translate(0, -50%);
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  letter-spacing: 0.1vw;
  &::after {
    content: "${(props) =>
      props.wager !== null && props.wager !== undefined
        ? `${props.currency || ""}` + props.wager
        : ""}";
  }
`;

class FinalJeopardy extends Component {
  render() {
    const { theme } = this.props;

    if (this.props.buzzer["activePlayer"]) {
      return (
        <Background background={theme.images.clueBackground}>
          <Response>{this.props.activeResponse["response"]}</Response>
          <Wager
            currency={this.props.currency}
            wager={this.props.activeResponse["wager"]}
          />
        </Background>
      );
    } else if (!this.props.board.category_revealed) {
      return <Background background={theme.images.cardFinalJeopardy} />;
    } else {
      return (
        <Background background={theme.images.clueBackground}>
          <FullscreenCategoryName small={this.props.board.category.length > 12}>
            {this.props.board.category}
          </FullscreenCategoryName>
        </Background>
      );
    }
  }
}

export default FinalJeopardy;
