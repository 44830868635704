import React from "react";
import { TableContainer, Table, TableBody, TableRow } from "@material-ui/core";
import CategoryCell from "./CategoryCell";
import ClueCell from "./ClueCell";

const GameTable = (props) => {
  const { board, toggleCategory, toggleClue } = props;
  const { clues, categories } = board || { clues: [], categories: [] };

  const clueSets = categories.map((category, index) => {
    const startingClueIndex = index * 5;
    const clueSet = clues.slice(startingClueIndex, startingClueIndex + 5);
    return {
      category: category,
      clues: clueSet,
    };
  });

  if (!board) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {clueSets.map((clueSet, index) => (
            <TableRow key={index}>
              <CategoryCell
                category={clueSet.category}
                toggleCategory={toggleCategory}
                index={index}
              />
              {clueSet.clues.map((clue, clueIndex) => (
                <ClueCell
                  key={clueIndex + 1}
                  clue={clue}
                  toggleClue={toggleClue}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GameTable;
