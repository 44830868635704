import React, { useState } from "react";
import "fontsource-roboto";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Menu as MenuIcon, WbIncandescent } from "@material-ui/icons";
import SettingsDrawer from "./SettingsDrawer";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
  },
});

const Navbar = (props) => {
  const {
    roomCode,
    volume,
    updateVolume,
    round,
    armBuzzers,
    resetBuzzers,
    buzzer,
    setCurrentRound,
    slowBuzzerMode,
    toggleBuzzerMode,
    buzzerTimeout,
    updateBuzzerTimeout,
    setModalOpen,
    playIntroMusic,
    fillBoard,
    emptyBoard,
    endRound,
    endGame,
    stopMusic,
    setEditMode,
    toggleColorMode,
    theme,
    updateTheme,
    logoBackground,
    toggleLogoBackground,
  } = props;

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  let title;
  switch (round) {
    case "triple_jeopardy":
      title = "Triple Jeopardy";
      break;
    case "double_jeopardy":
      title = "Double Jeopardy";
      break;
    case "final_jeopardy":
      title = "Final Jeopardy";
      break;
    default:
      title = "Jeopardy";
  }

  return (
    <AppBar position="static" color={buzzer.armed ? "secondary" : "primary"}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setIsOpen(!isOpen)}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title}>
          {roomCode} - {title}
        </Typography>
        <Button color="inherit" onClick={armBuzzers}>
          Arm <pre>[A]</pre>
        </Button>
        <Button color="inherit" onClick={() => resetBuzzers()}>
          Reset Buzzers <pre>[R]</pre>
        </Button>
        <Button color="inherit" onClick={() => setModalOpen(true)}>
          View Last Clue <pre>[V]</pre>
        </Button>
        <IconButton color="inherit" onClick={() => toggleColorMode()}>
          <WbIncandescent />
        </IconButton>
      </Toolbar>
      <SettingsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        slowBuzzerMode={slowBuzzerMode}
        toggleBuzzerMode={toggleBuzzerMode}
        buzzerTimeout={buzzerTimeout}
        updateBuzzerTimeout={updateBuzzerTimeout}
        volume={volume}
        updateVolume={updateVolume}
        round={round}
        setCurrentRound={setCurrentRound}
        setEditMode={setEditMode}
        playIntroMusic={playIntroMusic}
        fillBoard={fillBoard}
        emptyBoard={emptyBoard}
        stopMusic={stopMusic}
        endRound={endRound}
        endGame={endGame}
        theme={theme}
        updateTheme={updateTheme}
        logoBackground={logoBackground}
        toggleLogoBackground={toggleLogoBackground}
      />
    </AppBar>
  );
};

export default Navbar;
