import React from "react";
import CanvasDraw from "react-canvas-draw";

const Signature = (props) => {
  const { signature } = props;

  const vw = window.innerWidth / 100;

  return (
    <>
      <CanvasDraw
        canvasWidth={9.6 * vw}
        canvasHeight={6.2 * vw}
        hideGrid={true}
        disabled={true}
        backgroundColor="#00000000"
        saveData={signature}
      />
    </>
  );
};

export default Signature;
