import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/s32.jpg";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/s32.jpg";
import cardDailyDouble from "../images/cardDailyDouble/s32.jpg";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/s32.jpg";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/s32.jpg";
import fillBoard from "../sounds/fillBoard/2008.wav";
import cardFinalJeopardy from "../images/cardFinalJeopardy/s32.jpg";

export default class Season32Theme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    cardFinalJeopardy,
  };
  sounds = {
    ...themeDefaults.sounds,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
  };
  styles = { ...themeDefaults.styles };
}
