import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/s2.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/s2.png";
import cardDailyDouble from "../images/cardDailyDouble/s2.png";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/s2.png";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/s2.png";
import cardFinalJeopardy from "../images/cardFinalJeopardy/s2.png";
import fillBoard from "../sounds/fillBoard/1984.wav";
import thinkMusic from "../sounds/thinkMusic/1984.mp3";
import intro from "../sounds/intro/1985.mp3";

export default class Season2Theme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    cardFinalJeopardy,
  };
  sounds = {
    ...themeDefaults.sounds,
    intro,
    thinkMusic,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
  };
  styles = { ...themeDefaults.styles };
  fillBoardStyle = "retro";
}
