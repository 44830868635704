import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { base, firebaseAppAuth, providers } from "../base";
import withFirebaseAuth from "react-with-firebase-auth";
import { getRoomCode } from "../helpers/applicationHelper";
import CSVReader from "react-csv-reader";
import { IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import CreateGameModal from "./CreateGameModal";
import backgroundTexture from "../images/bgtexture.png";

const StyledMenu = styled.div`
  background-image: ${"url(" + backgroundTexture + ")"};
  background-repeat: repeat;
  height: 100vh;
  color: white;
  vertical-align: middle;
  overflow: scroll;
`;

const FormWrapper = styled.div`
  position: relative;
  display: block;
  vertical-align: middle;
  text-align: center;
  padding: 25px;
  h2 {
    margin: 0;
  }
  input {
    width: 50vw;
    padding: 10px;
    text-align: center;
    font-size: 20px;
  }
  button {
    font-size: 30px;
  }
  input[type="file"] {
    margin: 0 auto;
  }
  a {
    color: white;
  }
  * {
    display: block;
    margin: 5px auto 5px auto;
  }
`;

const NewGameButton = withRouter(({ history, createNewGame }) => (
  <button
    onClick={async () => {
      const roomCode = await createNewGame();
      history.push(`/${roomCode}/host`);
    }}>
    NEW GAME
  </button>
));

const ContinueGameButton = withRouter(({ history, roomCode }) => (
  <button
    onClick={async () => {
      history.push(`/${roomCode}/host`);
    }}>
    CONTINUE GAME
  </button>
));

class CreateGame extends Component {
  state = {
    archiveId: null,
    uploadData: null,
    password: null,
    modalOpen: false,
  };

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  async componentDidUpdate() {
    if (this.props.user && this.state.permitted === undefined) {
      try {
        const user = await base.get(`/users/${this.props.user.uid}`);
        this.setState({ permitted: !!user.host });
      } catch {
        this.setState({ permitted: false });
      }
    }
  }

  handleReadCSV = (data) => {
    this.setState({ uploadData: data });
  };

  createNewGameFromArchive = async () => {
    const game = await fetch(
      `https://us-central1-j-replay.cloudfunctions.net/getGameFromJArchive?game=${this.state.archiveId}`
    );

    const json = await game.json();
    return this.createGame(json);
  };

  createNewGameFromFile = async () => {
    let game = await fetch(
      "https://us-central1-j-replay.cloudfunctions.net/getGameFromUpload",
      {
        method: "POST",
        body: JSON.stringify({ clues: this.state.uploadData }),
        mode: "cors",
      }
    );
    game = await game.json();
    return await this.createGame(game);
  };

  createGame = async (game) => {
    const gameId = getRoomCode();
    const playerOne = { index: 0, name: "Player 1", score: 0 };

    const players = {
      0: playerOne,
      1: { index: 1, name: "Player 2", score: 0 },
      2: { index: 2, name: "Player 3", score: 0 },
      3: { index: 3, name: "Player 4", score: 0 },
      4: { index: 4, name: "Player 5", score: 0 },
      5: { index: 5, name: "Player 6", score: 0 },
      6: { index: 6, name: "Player 7", score: 0 },
      7: { index: 7, name: "Player 8", score: 0 },
      numberOfPlayers: 3,
    };

    game.slowBuzzerMode = false;
    game.finalInputEnabled = true;
    game.mediaStatus = "visible";
    game.buzzerTimeout = 3;
    game.archiveId = this.state.archiveId;
    game.password = this.state.password;
    game.logoBackground = true;

    const buzzer = {
      armed: false,
      activePlayer: null,
      playerInControl: playerOne,
    };

    const finalJeopardy = {
      wager: null,
      response: null,
    };

    await base.addToCollection("players", players, gameId);
    await base.addToCollection("games", game, gameId);
    await base.addToCollection("buzzers", buzzer, gameId);
    await base.addToCollection("mobileBuzzes", {}, gameId);
    await base.addToCollection(`mobileBuzzes/${gameId}/buzzes`, {}, "0");
    await base.addToCollection(`signatures`, {}, gameId);

    await base.addToCollection(`finals/${gameId}/data`, {}, "activeResponse");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "1");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "2");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "3");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "4");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "5");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "6");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "7");
    await base.addToCollection(`finals/${gameId}/data`, finalJeopardy, "8");
    await base.addToCollection(`theme`, { key: "season37" }, gameId);
    return gameId;
  };

  render() {
    const googleSheetLink =
      "https://docs.google.com/spreadsheets/d/1CgjsEZFDR53F6QoOpS3K9fi4ftE2nkHX5I6WWfgsNEo";

    const csvPath = "/custom_game_template.csv";

    return (
      <StyledMenu>
        <FormWrapper>
          <h2>Continue hosting</h2>
          <div>
            <input
              onChange={(e) => this.setState({ roomCode: e.target.value })}
              placeholder="Room Code"
            />
            <ContinueGameButton roomCode={this.state.roomCode} />
          </div>
        </FormWrapper>
        <FormWrapper>
          <h2>
            Host a new j-Archive game{" "}
            <IconButton
              color="secondary"
              onClick={() => this.setState({ modalOpen: true })}>
              <HelpIcon />
            </IconButton>
          </h2>
          <div>
            <input
              onChange={(e) => this.setState({ archiveId: e.target.value })}
              placeholder="Game ID"
            />
            <input
              type="password"
              placeholder="Host Screen Password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <NewGameButton
              createNewGame={this.createNewGameFromArchive.bind(this)}
            />
          </div>
        </FormWrapper>
        <CreateGameModal
          opened={this.state.modalOpen}
          close={() => this.setState({ modalOpen: false })}
        />
        <FormWrapper>
          <h2>
            Host a new custom game{" "}
            <IconButton
              color="secondary"
              onClick={() => this.setState({ modalOpen: true })}>
              <HelpIcon />
            </IconButton>
          </h2>
          <span>
            <a target="_blank" rel="noopener noreferrer" href={googleSheetLink}>
              Make your game in Google Sheets
            </a>
            or <a href={csvPath}>Download the CSV template</a>
          </span>
          <div>
            <input
              type="password"
              placeholder="Host Screen Password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <CSVReader
              onFileLoaded={this.handleReadCSV}
              parserOptions={{ header: true }}
            />
            <NewGameButton
              createNewGame={this.createNewGameFromFile.bind(this)}
            />
          </div>
        </FormWrapper>
      </StyledMenu>
    );
  }
}

export default withFirebaseAuth({ firebaseAppAuth, providers })(CreateGame);
