import React, { useState, useEffect } from "react";
import { Card, Grid, Modal } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Board from "./Board";
import Clue from "./Clue";
import Players from "./Players";
import Navbar from "./Navbar";
import FinalJeopardyReveal from "./FinalJeopardyReveal";

const Host = (props) => {
  const {
    roomCode,
    game,
    players,
    finalReveals,
    finals,
    buzzer,
    buzzes,
    wager,
    theme,
    setEditMode,
    armBuzzers,
    soundPlaying,
    updateVolume,
    setCurrentRound,
    round,
    resetBuzzers,
    toggleBuzzerMode,
    resetCurrentClue,
    markCorrectResponse,
    markIncorrectResponse,
    updateWager,
    updateBuzzerTimeout,
    revealClue,
    toggleClue,
    toggleCategory,
    toggleFinalCategory,
    setCurrentPlayer,
    isActivePlayer,
    isInControl,
    giveControl,
    fillBoard,
    emptyBoard,
    playThinkMusic,
    playIntroMusic,
    stopMusic,
    endRound,
    endGame,
    finalInputEnabled,
    toggleFinalInputEnabled,
    revealWager,
    revealResponse,
    mediaStatus,
    setMediaStatus,
    updateTheme,
    logoBackground,
    toggleLogoBackground,
    setScore,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [mostRecentClue, setMostRecentClue] = useState(null);
  const [colorMode, setColorMode] = useState("dark");

  const toggleColorMode = () => {
    colorMode === "light" ? setColorMode("dark") : setColorMode("light");
  };

  const colorModes = {
    dark: createMuiTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#90caf9",
        },
        secondary: {
          main: "#f48fb1",
        },
      },
    }),
    light: createMuiTheme({}),
  };

  useEffect(() => {
    const handleKeydown = async (event) => {
      if (["KeyA"].includes(event.code)) {
        armBuzzers();
      }

      if (["KeyR"].includes(event.code)) {
        resetBuzzers();
      }

      if (["KeyV"].includes(event.code)) {
        setModalOpen(true);
      }

      if (["Equal"].includes(event.code)) {
        markCorrectResponse();
      }

      if (["Minus"].includes(event.code)) {
        markIncorrectResponse();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [armBuzzers, resetBuzzers, markCorrectResponse, markIncorrectResponse]);

  useEffect(() => {
    if (game.currentClue) {
      setMostRecentClue(game.currentClue);
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [game.currentClue]);

  const colorTheme = colorModes[colorMode];

  return (
    <ThemeProvider theme={colorTheme}>
      <CssBaseline />
      <Grid container spacing={2}>
        <Modal
          open={modalOpen}
          onClose={() => {
            resetCurrentClue();
            setModalOpen(false);
          }}
        >
          <Card variant="outlined">
            <Clue
              setModalOpen={setModalOpen}
              clue={game.currentClue || mostRecentClue}
              clueIsActive={!!game.currentClue}
              revealClue={revealClue}
              buzzer={buzzer}
              buzzes={buzzes}
              armBuzzers={armBuzzers}
              markCorrectResponse={markCorrectResponse}
              markIncorrectResponse={markIncorrectResponse}
              wager={wager}
              updateWager={updateWager}
              players={players}
              isActivePlayer={isActivePlayer}
              setCurrentPlayer={setCurrentPlayer}
              round={round}
              playThinkMusic={playThinkMusic}
              stopMusic={stopMusic}
              soundPlaying={soundPlaying}
              finalInputEnabled={finalInputEnabled}
              toggleFinalInputEnabled={toggleFinalInputEnabled}
              mediaStatus={mediaStatus}
              setMediaStatus={setMediaStatus}
            />
          </Card>
        </Modal>
        <Navbar
          roomCode={roomCode}
          volume={soundPlaying.volume}
          updateVolume={updateVolume}
          round={round}
          buzzer={buzzer}
          armBuzzers={armBuzzers}
          resetBuzzers={resetBuzzers}
          setCurrentRound={setCurrentRound}
          slowBuzzerMode={game.slowBuzzerMode}
          buzzerTimeout={game["buzzerTimeout"]}
          updateBuzzerTimeout={updateBuzzerTimeout}
          toggleBuzzerMode={toggleBuzzerMode}
          setEditMode={setEditMode}
          fillBoard={fillBoard}
          emptyBoard={emptyBoard}
          playIntroMusic={playIntroMusic}
          stopMusic={stopMusic}
          endRound={endRound}
          endGame={endGame}
          setModalOpen={setModalOpen}
          toggleColorMode={toggleColorMode}
          theme={theme}
          updateTheme={updateTheme}
          logoBackground={logoBackground}
          toggleLogoBackground={toggleLogoBackground}
        />
        <Grid item sm={7}>
          <Board
            board={game[round]}
            round={round}
            currentClue={game.currentClue}
            toggleClue={toggleClue}
            toggleCategory={toggleCategory}
            toggleFinalCategory={toggleFinalCategory}
            finalInputEnabled={finalInputEnabled}
            toggleFinalInputEnabled={toggleFinalInputEnabled}
            column={2}
          />
          <Modal
            open={round === "final_jeopardy" && !!buzzer.activePlayer}
            onClose={() => {
              resetBuzzers();
            }}
          >
            <Card variant="outlined">
              <FinalJeopardyReveal
                clue={game.currentClue || mostRecentClue}
                activePlayer={buzzer.activePlayer}
                finals={finals}
                revealWager={revealWager}
                revealResponse={revealResponse}
                wager={wager}
                updateWager={updateWager}
                markCorrectResponse={markCorrectResponse}
                markIncorrectResponse={markIncorrectResponse}
              />
            </Card>
          </Modal>
        </Grid>
        <Grid item sm={5}>
          <Players
            players={players}
            finals={finals}
            buzzer={buzzer}
            round={round}
            finalReveals={finalReveals}
            isActivePlayer={isActivePlayer}
            setCurrentPlayer={setCurrentPlayer}
            isInControl={isInControl}
            giveControl={giveControl}
            setScore={setScore}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Host;
