import React, { Component } from "react";
import styled from "styled-components";
import { youtubeParams } from "../../../helpers/youtubeHelper";

const StyledClue = styled.div`
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column};
  width: 11.18vw;
  border-top: 0.5vw solid black;
  border-left: ${(props) => (props.column === 1 ? "0vw" : "0.25vw")} solid black;
  border-right: ${(props) => (props.column === 6 ? "0vw" : "0.25vw")} solid
    black;
  height: 6.5vw;
  text-align: center;
  display: flex;
  background-color: ${(props) => (props.transparent ? "" : "black")};
  background-image: ${(props) =>
    props.transparent ? "" : `url("${props.background}")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const ClueValue = styled.p`
  font-size: ${(props) => (props.smallValues ? "2.8vw" : "4vw")};
  font-family: "Swiss 911";
  color: rgb(216, 157, 70);
  text-shadow: ${[...Array(30).keys()]
    .map((value) => `${value / 100}vw ${value / 100}vw 0vw black`)
    .join(", ")};
  letter-spacing: 0.2vw;
  display: block;
  opacity: ${(props) => (props.hideValue() ? "0" : "1")};
  position: relative;
  margin: auto;

  .currency {
    font-size: ${(props) =>
      props.smallCurrency || props.smallValues ? "2vw" : "3.2vw"};
    position: relative;
    bottom: ${(props) => (props.smallCurrency ? "0.7vw" : "0.4vw")};
    margin-right: 0.25vw;
  }
`;

class Clue extends Component {
  showDailyDouble() {
    const { daily_double, daily_double_revealed, viewed } = this.props.clue;
    return daily_double && daily_double_revealed && !viewed ? true : false;
  }

  dailyDoubleCard(theme, clue) {
    if (typeof theme.images.cardDailyDouble === "function") {
      return theme.images.cardDailyDouble();
    }

    if (!clue.media) {
      return theme.images.cardDailyDouble;
    }

    const params = youtubeParams(clue.media);
    return params.audioOnly
      ? theme.images.cardDailyDoubleAudio
      : theme.images.cardDailyDoubleVideo;
  }

  hideValue() {
    return (
      this.props.clue.body === "" ||
      this.props.clue.viewed ||
      this.showDailyDouble()
    );
  }

  reverseCluePosition(row) {
    const reversedPositions = {
      2: 6,
      3: 5,
      4: 4,
      5: 3,
      6: 2,
    };

    return reversedPositions[row];
  }

  render() {
    const { theme, clue, smallValues, logoBackground } = this.props;
    const { value, viewed } = clue;

    const clueId = parseInt(clue.clueId);
    const column = Math.ceil((clueId + 1) / 5);
    let row = ((clueId + 1) % 5 || 5) + 1;
    row = theme.reverseClues ? this.reverseCluePosition(row) : row;

    return (
      <StyledClue
        background={
          this.showDailyDouble()
            ? this.dailyDoubleCard(theme, clue)
            : theme.images.clueBackground
        }
        showDailyDouble={this.showDailyDouble.bind(this)}
        row={row}
        column={column}
        transparent={viewed && logoBackground}
      >
        <ClueValue
          hideValue={this.hideValue.bind(this)}
          smallValues={smallValues}
          viewed={viewed}
          smallCurrency={this.props.currency.length > 1}
        >
          <span className="currency">{this.props.currency}</span>
          {value}
        </ClueValue>
      </StyledClue>
    );
  }
}

export default Clue;
