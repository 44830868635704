import React, { useState } from "react";

import Host from "./Host";
import Edit from "./Edit";

const HostScreen = (props) => {
  const [password, setPassword] = useState("");
  const [editMode, setEditMode] = useState(false);

  if (password !== props.game.password) {
    return (
      <input
        autoFocus
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter host password"
        style={{ width: "50%", fontSize: "40px" }}
        type="password"
      />
    );
  }

  if (editMode) {
    return <Edit {...props} setEditMode={setEditMode} />;
  } else {
    return <Host {...props} setEditMode={setEditMode} />;
  }
};

export default HostScreen;
