import React from "react";
import { Fab, Grid } from "@material-ui/core";
import JudgeControls from "./JudgeControls";
import WagerControls from "./WagerControls";

const SignalControls = (props) => {
  const {
    buzzer,
    armBuzzers,
    markCorrectResponse,
    markIncorrectResponse,
    wager,
    updateWager,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Fab
          color={buzzer.armed ? "secondary" : "primary"}
          onClick={() => armBuzzers()}
        >
          Arm <pre>[A]</pre>
        </Fab>
      </Grid>
      <Grid item>
        <JudgeControls
          markCorrectResponse={markCorrectResponse}
          markIncorrectResponse={markIncorrectResponse}
        />
      </Grid>
      <Grid item>
        <WagerControls wager={wager} updateWager={updateWager} />
      </Grid>
    </Grid>
  );
};

export default SignalControls;
