import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/space.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/space.png";
import cardTripleJeopardy from "../images/cardTripleJeopardy/space.png";
import cardFinalJeopardy from "../images/cardFinalJeopardy/space.png";
import clueBackground from "../images/clueBackground/dark.png";
import clueBackgroundDailyDouble from "../images/clueBackgroundDailyDouble/dark.png";
import cardDailyDouble from "../images/cardDailyDouble/space.png";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/space.png";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/space.png";
import clueBackgroundMediaClue from "../images/clueBackgroundMediaClue/dark.png";
import thinkMusic from "../sounds/thinkMusic/goat.mp3";
import intro from "../sounds/intro/goat.mp3";

export default class SpaceTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardTripleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    cardFinalJeopardy,
    clueBackground,
    podiumBackground: clueBackground,
    clueBackgroundDailyDouble,
    clueBackgroundMediaClue,
  };
  sounds = {
    ...themeDefaults.sounds,
    thinkMusic,
    intro,
    outro: intro,
  };
  styles = { ...themeDefaults.styles };
}
