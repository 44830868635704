import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/rock_and_roll.png";
import cardDailyDouble from "../images/cardDailyDouble/rock_and_roll.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/rock_and_roll.png";
import clueBackground from "../images/clueBackground/rock_and_roll.png";
import clueBackgroundDailyDouble from "../images/clueBackgroundDailyDouble/rock_and_roll.png";
import intro from "../sounds/intro/rock_and_roll.mp3";
import thinkMusic from "../sounds/thinkMusic/rock_and_roll.mp3";
import fillBoard from "../sounds/fillBoard/1984.wav";

export default class RockAndRollTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio: cardDailyDouble,
    cardDailyDoubleVideo: cardDailyDouble,
    clueBackground,
    clueBackgroundDailyDouble,
    cardFinalJeopardy: cardJeopardy,
  };
  sounds = {
    ...themeDefaults.sounds,
    thinkMusic,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
    intro,
    outro: intro,
  };
  fillBoardStyle = "retro";
  styles = { ...themeDefaults.styles };
}
