import React from "react";
import { Modal, Card } from "@material-ui/core";

const CreateGameModal = (props) => {
  const { opened, close } = props;

  return (
    <Modal open={opened} onClose={close}>
      <Card variant="outlined">
        <h2>Creating a new game</h2>
        <strong>Host Screen Password</strong>
        <p>
          The Host Screen Password is used to access the created game. Only the
          host(s) of the game should know this password.
        </p>
        <br />
        <h3>J-Archive</h3>
        <strong>Game ID</strong>
        <p>Enter the game_id from the j-archive URL.</p>
        <pre>
          https://j-archive.com/showgame.php?game_id=1234 --- 1234 is the ID
        </pre>
        <br />
        <strong>Image Clues</strong>
        <p>
          Images for picture clues will almost always be missing from j-archive
          games. Replace them with valid image URLs before hosting your game.
          Images should be smaller than 800px x 800px.
        </p>
      </Card>
    </Modal>
  );
};

export default CreateGameModal;
