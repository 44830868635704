import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/s17.jpg";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/s17.jpg";
import cardTripleJeopardy from "../images/cardTripleJeopardy/s17.jpg";
import cardDailyDouble from "../images/cardDailyDouble/s17.jpg";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/s17.jpg";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/s17.jpg";
import fillBoard from "../sounds/fillBoard/1984.wav";
import cardFinalJeopardy from "../images/cardFinalJeopardy/s17.jpg";
import stageBackground from "../images/stageBackground/s24.png";

export default class Season17Theme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardTripleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    cardFinalJeopardy,
    stageBackground,
  };
  sounds = {
    ...themeDefaults.sounds,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
  };
  fillBoardStyle = "retro";
  styles = { ...themeDefaults.styles };
}
