import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Radio,
  Checkbox,
  TextField,
} from "@material-ui/core";
import PlayerButton from "./controls/PlayerButton";

const Player = (props) => {
  const {
    player,
    ranking,
    distanceFromFirst,
    distanceAhead,
    round,
    final,
    finalRevealed,
    isActivePlayer,
    isInControl,
    setCurrentPlayer,
    giveControl,
    setScore,
  } = props;

  const formatNumber = (number) =>
    number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  const ordinalNumber = (position) =>
    ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"][position];

  return (
    <TableRow>
      <TableCell style={{ padding: "6px 0 6px 0" }}>
        {round !== "final_jeopardy" ? (
          <Radio
            checked={isInControl(player)}
            onChange={() => giveControl(player)}
          />
        ) : (
          <Checkbox
            checked={finalRevealed}
            onClick={() => setCurrentPlayer(player, true)}
          />
        )}
        <PlayerButton
          player={player}
          isActivePlayer={isActivePlayer}
          setCurrentPlayer={setCurrentPlayer}
        />
      </TableCell>
      <TableCell>
        <Typography variant="h6">{final.wager}</Typography>
      </TableCell>
      <TableCell align="right">
        <strong>{ordinalNumber(ranking)}</strong>
        <br />
        <TextField
          variant="outlined"
          label="Score"
          value={player.score}
          onChange={(event) => setScore(player, event.target.value)}
        />
        <br />
        {distanceFromFirst > 0 && `down ${formatNumber(distanceFromFirst)}`}
        {distanceAhead > 0 && `up ${formatNumber(distanceAhead)}`}
      </TableCell>
    </TableRow>
  );
};

export default Player;
