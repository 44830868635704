import React, { Component } from "react";
import PropTypes from "prop-types";

import { base } from "../../base";

import styled from "styled-components";
import Signature from "./podium/Signature";
import Timer from "./podium/Timer";

const StyledPodium = styled.div`
  overflow: visible;
  background-color: black;
  justify-self: center;
  text-align: center;
  max-width: 10vw;
  width: 100%;
  .spacer {
    background-image: url("${(props) => props.spacer}");
    background-position: center;
    background-size: cover;
    height: 1.5vw;
    border-left: 0.2vw solid black;
    border-right: 0.2vw solid black;
    margin: 0;
    .left-right-spacer {
      height: 100%;
      width: 100%;
      box-shadow: inset ${(props) => (props.isBuzzed ? "20vw" : "0px")} 0 0 0
        #fff;
      transition: box-shadow ${(props) => (props.isBuzzed ? "0.25s" : "0s")}
        linear;
    }
    .right-left-spacer {
      height: 100%;
      width: 100%;
      box-shadow: inset ${(props) => (props.isBuzzed ? "-20vw" : "0px")} 0 0 0
        #fff;
      transition: box-shadow ${(props) => (props.isBuzzed ? "0.25s" : "0s")}
        linear;
    }
  }
`;

const Score = styled.p`
  border: 0.2vw solid black;
  font-size: ${(props) =>
    Math.abs(props.score) >= 100000 ? "1.25vw" : "1.75vw"};
  line-height: 2.5vw;
  font-family: "Zurich";
  text-shadow: ${[...Array(20).keys()]
    .map((value) => `${value / 100}vw ${value / 100}vw 0vw black`)
    .join(", ")};
  color: ${(props) => (props.score < 0 ? "red" : "white")};
  background-image: ${(props) => `url("${props.background}")`};
  margin: 0;

  ${(props) => props.theme.styles.podiumScore(props)}}
`;

const Currency = styled.span`
  margin-right: 0.2vw;
  ${(props) => props.smallCurrency && "font-size: 1.25vw;"}

  ${(props) => props.theme.styles.podiumCurrency(props)}}
`;

const NameSection = styled.div`
  height: 6.5vw;
  border: 0.2vw solid black;
  background-image: ${(props) => `url("${props.background}")`};
`;

const Name = styled.p`
  font-size: ${(props) => (props.name.length > 8 ? "2vw" : "3vw")};
  padding-top: ${(props) => (props.name.length > 8 ? "1vw" : "0")};
  font-family: "SueEllen";
  color: white;
  margin: 0;
`;

const Indicator = styled.div`
  width: 0.3vw;
  height: 0.3vw;
  background-color: white;
  margin-left: 0.3vw;
`;

class Podium extends Component {
  static propTypes = {
    player: PropTypes.shape({
      buzzer: PropTypes.string,
      name: PropTypes.string,
      score: PropTypes.number,
    }),
    isBuzzed: PropTypes.bool,
  };

  state = { signature: { data: "" } };

  componentDidMount() {
    base.syncDoc(
      `signatures/${this.props.gameId.toUpperCase()}/players/${
        this.props.player.index
      }`,
      {
        context: this,
        state: "signature",
      }
    );
  }

  isInControl() {
    if (!!this.props.buzzer.playerInControl) {
      return (
        this.props.buzzer.playerInControl.index === this.props.player.index
      );
    } else {
      return false;
    }
  }

  score() {
    return this.props.player.score
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      .replace("-", "");
  }

  signatureExists() {
    return (
      !!this.state.signature.data &&
      JSON.parse(this.state.signature.data).lines.length > 0
    );
  }

  render() {
    const { theme, player } = this.props;
    const { name } = player;
    const currency = this.props.currency || "";
    return (
      <StyledPodium
        isBuzzed={this.props.isBuzzed}
        spacer={theme.images.podiumSpacer}
      >
        <Timer isBuzzed={this.props.isBuzzed && this.props.timerEnabled} />
        <Score
          theme={theme}
          score={this.props.player.score}
          background={theme.images.podiumBackground}
        >
          {this.props.player.score < 0 ? "-" : ""}
          <Currency theme={theme} smallCurrency={currency.length > 1}>
            {currency}
          </Currency>
          {this.score()}
        </Score>
        <div className="spacer">
          <div className="left-right-spacer">
            <div className="right-left-spacer"></div>
          </div>
        </div>
        <NameSection background={theme.images.podiumBackground}>
          {this.signatureExists() ? (
            <Signature
              signature={this.state.signature.data}
              background={theme.images.podiumBackground}
            />
          ) : (
            <Name name={name}>{name}</Name>
          )}
        </NameSection>
        {this.isInControl() && <Indicator />}
      </StyledPodium>
    );
  }
}

export default Podium;
