import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/afterdark.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/afterdark.png";
import cardTripleJeopardy from "../images/cardTripleJeopardy/afterdark.png";
import cardFinalJeopardy from "../images/cardFinalJeopardy/afterdark.png";
import cardDailyDouble from "../images/cardDailyDouble/afterdark.png";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/afterdark.png";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/afterdark.png";

export default class ChaosTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardTripleJeopardy,
    cardFinalJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
  };
  sounds = themeDefaults.sounds;
  styles = themeDefaults.styles;
}
