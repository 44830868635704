import clueBackground from "../images/clueBackground/blue.png";
import cardJeopardy from "../images/cardJeopardy/s37.jpg";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/s37.jpg";
import cardFinalJeopardy from "../images/cardFinalJeopardy/s37.jpg";
import cardDailyDouble from "../images/cardDailyDouble/s37.jpg";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/s37.jpg";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/s37.jpg";
import podiumSpacer from "../images/podiumSpacer/blue.png";
import clueBackgroundDailyDouble from "../images/clueBackgroundDailyDouble/blue.jpg";
import clueBackgroundMediaClue from "../images/clueBackgroundMediaClue/blue.jpg";
import stageBackground from "../images/stageBackground/s36.png";
import podiumsBackground from "../images/podiumsBackground/modern.png";

import fillBoard from "../sounds/fillBoard/2013.wav";
import finalReveal from "../sounds/finalReveal/original.wav";
import endOfRound from "../sounds/endOfRound/original.wav";
import outro from "../sounds/outro/2008.wav";
import dailyDouble from "../sounds/dailyDouble/original.wav";
import thinkMusic from "../sounds/thinkMusic/2008.wav";
import timeOut from "../sounds/timeOut/original.wav";
import intro from "../sounds/intro/2008.mp3";

const themeDefaults = {
  images: {
    clueBackground,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleVideo,
    cardDailyDoubleAudio,
    cardFinalJeopardy,
    podiumSpacer,
    clueBackgroundDailyDouble,
    clueBackgroundMediaClue,
    podiumBackground: clueBackground,
    podiumsBackground,
    stageBackground,
  },
  sounds: {
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
    finalReveal,
    endOfRound,
    outro,
    dailyDouble,
    thinkMusic,
    timeOut,
    intro,
  },
  styles: {
    podiumScore: (_props) => null,
    podiumCurrency: (_props) => null,
  },
};

export default themeDefaults;
