import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/chaos.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/chaos.png";
import cardTripleJeopardy from "../images/cardTripleJeopardy/chaos.png";
import cardFinalJeopardy from "../images/cardFinalJeopardy/chaos.png";
import cardDailyDouble from "../images/cardDailyDouble/chaos.png";
import cardDailyDouble2 from "../images/cardDailyDouble/chaos2.png";
import cardDailyDouble3 from "../images/cardDailyDouble/chaos3.png";
import cardDailyDouble4 from "../images/cardDailyDouble/chaos4.png";
import cardDailyDouble5 from "../images/cardDailyDouble/chaos5.png";
import cardDailyDouble6 from "../images/cardDailyDouble/explosion.gif";
import fillBoard from "../sounds/fillBoard/whammy.wav";
import thinkMusic from "../sounds/thinkMusic/chaos.wav";

const dailyDoubleRandomizer = () => {
  const cards = [
    cardDailyDouble,
    cardDailyDouble2,
    cardDailyDouble3,
    cardDailyDouble4,
    cardDailyDouble5,
    cardDailyDouble6,
  ];

  return cards[Math.floor(Math.random() * cards.length)];
};

export default class ChaosTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardTripleJeopardy,
    cardFinalJeopardy,
    cardDailyDouble: dailyDoubleRandomizer,
    cardDailyDoubleAudio: dailyDoubleRandomizer,
    cardDailyDoubleVideo: dailyDoubleRandomizer,
  };
  sounds = {
    ...themeDefaults.sounds,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
    thinkMusic
  };
  fillBoardStyle = "retro";
  styles = { ...themeDefaults.styles };
}
