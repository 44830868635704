import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/super.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/super.png";
import cardDailyDouble from "../images/cardDailyDouble/super.png";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/super.png";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/super.png";
import cardFinalJeopardy from "../images/cardFinalJeopardy/super.png";
import stageBackground from "../images/stageBackground/s24.png";

import fillBoard from "../sounds/fillBoard/1984.wav";
import thinkMusic from "../sounds/thinkMusic/1984.mp3";
import intro from "../sounds/intro/1985.mp3";
import outro from "../sounds/outro/super.wav";
import endOfRound from "../sounds/endOfRound/super.wav";

export default class SuperJeopardyTheme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    cardFinalJeopardy,
    stageBackground,
  };
  sounds = {
    ...themeDefaults.sounds,
    intro,
    outro,
    thinkMusic,
    endOfRound,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
  };
  styles = { ...themeDefaults.styles };
  fillBoardStyle = "retro";
}
