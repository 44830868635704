import React from "react";
import { Grid } from "@material-ui/core";
import PlayerButton from "./PlayerButton";

const PlayerControls = (props) => {
  const { players, buzzes, isActivePlayer, setCurrentPlayer } = props;

  const buzzForPlayer = (player) => {
    return buzzes.find((buzz) => buzz.player?.index === player.index);
  };

  return (
    <Grid container spacing={2}>
      {players.map((player, index) => (
        <Grid item key={index}>
          <PlayerButton
            key={index}
            player={player}
            buzz={buzzForPlayer(player)}
            isActivePlayer={isActivePlayer}
            setCurrentPlayer={setCurrentPlayer}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlayerControls;
