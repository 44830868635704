import themeDefaults from "./themeDefaults";
import cardJeopardy from "../images/cardJeopardy/s29.png";
import cardDoubleJeopardy from "../images/cardDoubleJeopardy/s29.jpg";
import cardDailyDouble from "../images/cardDailyDouble/s29.jpg";
import cardDailyDoubleAudio from "../images/cardDailyDoubleAudio/s29.jpg";
import cardDailyDoubleVideo from "../images/cardDailyDoubleVideo/s29.jpg";
import fillBoard from "../sounds/fillBoard/1984.wav";
import cardFinalJeopardy from "../images/cardFinalJeopardy/s29.jpg";

export default class Season29Theme {
  images = {
    ...themeDefaults.images,
    cardJeopardy,
    cardDoubleJeopardy,
    cardDailyDouble,
    cardDailyDoubleAudio,
    cardDailyDoubleVideo,
    cardFinalJeopardy,
  };
  sounds = {
    ...themeDefaults.sounds,
    fillBoard,
    fillBoardDoubleJeopardy: fillBoard,
  };
  fillBoardStyle = "retro";
  styles = { ...themeDefaults.styles };
}
