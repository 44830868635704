import React, { useState } from "react";
import styled from "styled-components";
import { youtubeParams } from "../../helpers/youtubeHelper";
import YouTube from "react-youtube";

const StyledFullscreenClue = styled.div`
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  background-color: black;
  background-image: ${(props) => `url("${props.background}")`};
  background-size: cover;
  background-position: center;
  height: 39vw;
`;

const FullscreenValue = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Swiss 911";
  color: rgb(216, 157, 70);
  text-shadow: ${(props) => {
    const baseValue = props.smallValue ? 10 : 20;
    return [...Array(baseValue).keys()]
      .map((value) => `${value / 10}vw ${value / 10}vw 0vw black`)
      .join(", ");
  }};
  font-size: ${(props) => (props.smallValue ? "18vw" : "25vw")};
  text-align: center;
  position: absolute;
  top: ${(props) => (props.smallValue ? "72%" : "63%")};
  transform: translate(0, -50%);
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0 10% 0 10%;
  letter-spacing: ${(props) => (props.smallValue ? "0.8vw" : "1.3vw")};
  padding: 0;
`;

const Currency = styled.span`
  font-family: "Swiss 911";
  color: rgb(216, 157, 70);
  font-size: ${(props) => {
    if (props.smallCurrency) {
      return "10vw";
    } else if (props.smallValue) {
      return "13vw";
    } else {
      return "20vw";
    }
  }};
  margin-right: 1vw;
  position: relative;
  bottom: ${(props) => (props.smallCurrency ? "5vw" : "2vw")};
`;

const TextClue = styled.div`
  color: white;
  font-family: "Korinna";
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: ${[...Array(40).keys()]
    .map((value) => `${value / 100}vw ${value / 100}vw 0vw black`)
    .join(", ")};
  letter-spacing: 0.1vw;
  font-size: 3vw;
  line-height: 3.5vw;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 50%;
  margin-right: ${(props) => (props.length > 120 ? "-60%" : "-33.3%")};
  transform: translate(-50%, -50%);
  padding: 0 10% 0 10%;
  white-space: pre-line;
`;

const ImageClue = styled.img`
  height: 100%;
  max-width: 100%;
  margin: auto;
  border-width: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border: 0.3vw solid black;
`;

const VideoContainer = styled.div`
  .video {
    height: 39vw;
  }
`;

const CategoryName = styled.div`
  opacity: 0.3;
  color: white;
  font-family: "Swiss 921";
  font-size: 2vw;
  text-align: right;
  padding-right: 0.5vw;
  text-transform: uppercase;
  letter-spacing: 0.03vw;
`;

const youtubeOnReady = (event) => {
  event.target.setVolume(100);
  event.target.unMute();
};

const FullscreenClue = (props) => {
  const [hasPlayedOnce, setHasPlayedOnce] = useState(false);
  const { clue, currency, mediaStatus, theme, setMediaStatus } = props;
  const [display, setDisplay] = useState("value");

  let background = clue?.daily_double
    ? theme.images.clueBackgroundDailyDouble
    : theme.images.clueBackground;

  const playerParams = clue.media.includes("youtube")
    ? youtubeParams(clue.media)
    : {};

  if (playerParams.autoplay && !hasPlayedOnce) {
    playerParams.audioOnly
      ? setMediaStatus("hidden")
      : setMediaStatus("visible");
    setHasPlayedOnce(true);
  }

  setTimeout(() => setDisplay("body"), 1000);

  if (!clue) {
    return null;
  } else if (display === "body" || clue.daily_double || clue.value === 0) {
    if (!!clue.media && mediaStatus !== "disabled") {
      if (playerParams.id) {
        const opts = {
          height: "100%",
          width: "100%",
          playerVars: {
            autoplay: 1,
            controls: 0,
            start: playerParams["start"],
            end: playerParams["end"],
          },
        };

        return (
          <StyledFullscreenClue background={background}>
            {mediaStatus === "hidden" && (
              <>
                <CategoryName>{clue.category}</CategoryName>
                <TextClue length={clue.body.length}>{clue.body}</TextClue>
              </>
            )}

            <VideoContainer
              style={mediaStatus === "hidden" ? { display: "none" } : {}}
            >
              <YouTube
                videoId={playerParams.id}
                containerClassName="video"
                opts={opts}
                onReady={(event) => {
                  youtubeOnReady(event);
                }}
                onEnd={() => setMediaStatus("disabled")}
              />
            </VideoContainer>
          </StyledFullscreenClue>
        );
      } else {
        return (
          <StyledFullscreenClue
            background={theme.images.clueBackgroundMediaClue}
          >
            <ImageClue src={clue.media} scrolling="no" />
          </StyledFullscreenClue>
        );
      }
    } else {
      return (
        <StyledFullscreenClue background={background}>
          <CategoryName>{clue.category}</CategoryName>
          <TextClue length={clue.body.length}>{clue.body}</TextClue>
        </StyledFullscreenClue>
      );
    }
  } else if (display === "value") {
    const smallValue = clue.value > 99999;
    return (
      <StyledFullscreenClue background={theme.images.clueBackground}>
        <FullscreenValue smallValue={smallValue}>
          <Currency smallCurrency={currency.length > 1} smallValue={smallValue}>
            {currency}
          </Currency>
          {clue.value}
        </FullscreenValue>
      </StyledFullscreenClue>
    );
  }
};

export default FullscreenClue;
