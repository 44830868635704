import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Field } from "formik";

const EditPlayers = () => {
  return (
    <Grid container spacing={1} direction="column">
      <Typography variant="h4">Players</Typography>
      <Grid item>
        <Typography>
          Number of Active Players:{" "}
          <Field
            type="number"
            min="1"
            max="8"
            name={`players[numberOfPlayers]`}
          />
        </Typography>
      </Grid>
      {[...Array(8).keys()].map((index) => (
        <Grid item>
          <Typography>
            Player {index + 1}: <Field name={`players[${index}][name]`} />
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default EditPlayers;
