import React from "react";
import "fontsource-roboto";
import { makeStyles } from "@material-ui/core/styles";
import {
  SwipeableDrawer,
  Grid,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { MusicNote } from "@material-ui/icons";
import VolumeSlider from "./settings/VolumeSlider";
import RoundSettings from "./settings/RoundSettings";
import ThemeSettings from "./settings/ThemeSettings";
import SignalSettings from "./settings/SignalSettings";

const useStyles = makeStyles({
  drawerContents: {
    maxWidth: "250px",
    margin: "10px",
  },
});

const SettingsDrawer = (props) => {
  const {
    isOpen,
    setIsOpen,
    volume,
    updateVolume,
    round,
    setCurrentRound,
    slowBuzzerMode,
    toggleBuzzerMode,
    buzzerTimeout,
    updateBuzzerTimeout,
    playIntroMusic,
    fillBoard,
    emptyBoard,
    stopMusic,
    endRound,
    endGame,
    setEditMode,
    theme,
    updateTheme,
    logoBackground,
    toggleLogoBackground,
  } = props;

  const classes = useStyles();

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <Grid
        className={classes.drawerContents}
        container
        direction="column"
        spacing={5}
      >
        <Grid item>
          <RoundSettings
            round={round}
            setCurrentRound={setCurrentRound}
            setIsOpen={setIsOpen}
          />
        </Grid>
        <Grid item>
          <ThemeSettings theme={theme} updateTheme={updateTheme} />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              window.open(window.location.href.replace("/host", "/watch"));
              setIsOpen(false);
            }}
          >
            Launch Game Board
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setEditMode(true);
              setIsOpen(false);
            }}
          >
            Edit Game and Players
          </Button>
        </Grid>
        <Grid item>
          <SignalSettings
            slowBuzzerMode={slowBuzzerMode}
            toggleBuzzerMode={toggleBuzzerMode}
            buzzerTimeout={buzzerTimeout}
            updateBuzzerTimeout={updateBuzzerTimeout}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={logoBackground}
                onChange={toggleLogoBackground}
              />
            }
            label="Logo Background"
          />
        </Grid>
        <Grid container item direction="column" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                fillBoard();
                setIsOpen(false);
              }}
            >
              <MusicNote /> Fill Board
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                emptyBoard();
              }}
            >
              Empty Board
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                playIntroMusic();
              }}
            >
              <MusicNote /> Main Theme
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={endRound} variant="outlined">
              <MusicNote /> Time's Up
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={endGame} variant="outlined">
              <MusicNote /> End Theme
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={stopMusic} variant="outlined" color="secondary">
              Stop Music
            </Button>
          </Grid>
          <Grid item>
            <VolumeSlider volume={volume} updateVolume={updateVolume} />
          </Grid>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export default SettingsDrawer;
