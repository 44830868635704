import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import EditBoard from "./edit/EditBoard";
import EditPlayers from "./edit/EditPlayers";
import EditClueValues from "./edit/EditClueValues";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    maxHeight: "100vh",
    overflowY: "auto",
  },
});

const Edit = (props) => {
  const classes = useStyles();

  const { game, allPlayers, updateGame, updatePlayers, setEditMode } = props;
  const [initialValues, setInitialValues] = useState({
    game,
    players: allPlayers,
  });

  const [clueValues, setClueValues] = useState({
    jeopardy: [200, 400, 600, 800, 1000],
    double_jeopardy: [400, 800, 1200, 1600, 2000],
    triple_jeopardy: [600, 1200, 1800, 2400, 3000],
  });

  const updateClueValues = () => {
    const clonedGame = game;

    ["jeopardy", "double_jeopardy", "triple_jeopardy"].forEach((round) => {
      clueValues[round].forEach((value, index) => {
        while (index < 30) {
          clonedGame[round]["clues"][index]["value"] = value;
          index += 5;
        }
      });
    });

    setInitialValues({ ...initialValues, game: clonedGame });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        setEditMode(false);
        updateGame(values.game);
        updatePlayers(values.players);
      }}
    >
      {() => (
        <Form>
          <button type="submit">{`SAVE & CONTINUE`}</button>
          <button
            onClick={() => setEditMode(false)}
          >{`Exit without saving`}</button>
          <Grid container className={classes.container}>
            <Grid item sm={12} lg={10}>
              <EditBoard game={game} />
            </Grid>
            <Grid item sm={12} lg={2}>
              <EditPlayers players={allPlayers} />
              <EditClueValues
                clueValues={clueValues}
                setClueValues={setClueValues}
                updateClueValues={updateClueValues}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Edit;
