import React, { Component } from "react";
import styled from "styled-components";
import { maxBy } from "lodash";

import FinalJeopardy from "./FinalJeopardy";
import Clue from "./board/Clue";
import FullscreenClue from "./FullscreenClue";

const StyledBoard = styled.div`
  margin: 2.5vw 16vw;
  background-color: black;
  background-image: ${(props) => `url("${props.theme.images.cardJeopardy}")`};
  background-size: contain;
  background-position: 0% 340%;
  background-repeat: no-repeat;
  border: 0.5vw solid black;
  ${(props) =>
    props.armed &&
    "box-shadow: -0.1vw 0px 0px 0px white, 0.1vw 0px 0px 0px white;"}
`;

const BoardGrid = styled.div`
  display: grid;
  box-shadow: -.5vw 0vw 0px 0px black, .5vw 0px 0px 0px black, 0vw -.5vw 0px 0px black, 0vw .5vw 0px 0px black;
`;

const Category = styled.div`
  place-items: center;
  width: 11.18vw;
  border-bottom: 0.5vw solid black;
  border-left: ${(props) => (props.column === 1 ? "0vw" : "0.25vw")} solid black;
  border-right: ${(props) => (props.column === 6 ? "0vw" : "0.25vw")} solid
    black;
  height: 6.5vw;
  grid-row: 1;
  grid-column: ${(props) => props.column};
  text-align: center;
  display: inherit;
  background-color: black;
  background-image: ${(props) => `url("
      ${props.active ? props.emptyBackground : props.categoryImage()}
    ")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const CategoryName = styled.p`
  font-family: "Swiss 921";
  font-size: ${(props) => (props.small ? "1.2vw" : "1.5vw")};
  line-height: ${(props) => (props.small ? "1.1vw" : "1.4vw")};
  color: white;
  letter-spacing: 0.05vw;
  text-shadow: 0.04vw 0.04vw 0.005vw #000000;
  margin: auto;
  padding: 0 0.5vw 0 0.5vw;
  opacity: ${(props) => (props.active ? "1" : "0")};
`;

class Board extends Component {
  categoryImage() {
    switch (this.props.board.name) {
      case "jeopardy":
        return this.props.theme.images.cardJeopardy;
      case "double_jeopardy":
        return this.props.theme.images.cardDoubleJeopardy;
      case "triple_jeopardy":
        return (
          this.props.theme.images.cardTripleJeopardy ||
          this.props.theme.images.cardJeopardy
        );
      default:
        return this.props.theme.images.cardJeopardy;
    }
  }

  boardContent() {
    const maxClueValue = maxBy(this.props.board.clues, (clue) =>
      parseInt(clue.value)
    ).value;
    const smallValues = maxClueValue > 99999;

    if (this.props.board.name === "final_jeopardy") {
      return (
        <StyledBoard armed={this.props.buzzer.armed} theme={this.props.theme}>
          <FinalJeopardy
            theme={this.props.theme}
            board={this.props.board}
            buzzer={this.props.buzzer}
            currency={this.props.currency}
            activeClue={this.props.activeClue}
            activeResponse={this.props.activeResponse}
          />
        </StyledBoard>
      );
    } else {
      return (
        <StyledBoard armed={this.props.buzzer.armed} theme={this.props.theme}>
          <BoardGrid>
            {Object.keys(this.props.board.categories).map((key) => {
              const firstClueIndex = key * 5;
              const lastClueIndex = firstClueIndex + 4;
              const categoryClues = this.props.board.clues.slice(
                firstClueIndex,
                lastClueIndex + 1
              );

              const categoryComplete = categoryClues.every((clue) => clue.viewed);
              const categoryName = this.props.board.categories[key].name;

              return (
                <Category
                  key={key}
                  column={parseInt(key) + 1}
                  active={this.props.board.categories[key].active}
                  emptyBackground={this.props.theme.images.clueBackground}
                  categoryImage={this.categoryImage.bind(this)}
                >
                  <CategoryName
                    active={this.props.board.categories[key].active}
                    small={categoryName.length >= 24}
                  >
                    {categoryComplete ? " " : categoryName}
                  </CategoryName>
                </Category>
              );
            })}
            {Object.keys(this.props.board.clues).map((key) => (
              <Clue
                theme={this.props.theme}
                logoBackground={this.props.logoBackground}
                key={key}
                smallValues={smallValues}
                currency={this.props.currency}
                clue={this.props.board.clues[key]}
              />
            ))}
          </BoardGrid>
        </StyledBoard>
      );
    }
  }

  render() {
    if (!this.props.board) {
      return null;
    }

    if (!this.props.activeClue) {
      return this.boardContent();
    } else {
      return (
        <StyledBoard armed={this.props.buzzer.armed} theme={this.props.theme}>
          <FullscreenClue
            theme={this.props.theme}
            mediaStatus={this.props.mediaStatus}
            setMediaStatus={this.props.setMediaStatus}
            currency={this.props.currency}
            clue={this.props.activeClue}
          />
        </StyledBoard>
      );
    }
  }
}

export default Board;
