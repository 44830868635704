import React from "react";
import { Grid, FormControlLabel, Switch, TextField } from "@material-ui/core";

const SignalSettings = (props) => {
  const {
    slowBuzzerMode,
    toggleBuzzerMode,
    buzzerTimeout,
    updateBuzzerTimeout,
  } = props;
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          label="Buzzer Timeout"
          type="number"
          value={buzzerTimeout}
          onChange={(e) => updateBuzzerTimeout(e.target.value)}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch checked={!slowBuzzerMode} onChange={toggleBuzzerMode} />
          }
          label="Fast Buzzers"
        />
      </Grid>
    </Grid>
  );
};

export default SignalSettings;
