import React from "react";
import { Button } from "@material-ui/core";

const PlayerButton = (props) => {
  const { player, buzz, isActivePlayer, setCurrentPlayer } = props;

  return (
    <Button
      variant={isActivePlayer(player) ? "contained" : "outlined"}
      color={player.locked ? "secondary" : "primary"}
      onClick={() => setCurrentPlayer(player, true)}
    >
      {player.name}
      {buzz?.speed && <br />}
      {buzz?.speed && `${buzz.speed / 1000} S`}
    </Button>
  );
};

export default PlayerButton;
